import styled, { css } from 'styled-components';
import { Theme } from '../../../../../theme';
import { EssoCardBlob, IconLogoEsso } from '../../../../../assets';

export const CardWrapper = styled.div(() => {
  return css`
    cursor: default;
    position: relative;
    width: 100%;
    aspect-ratio: 3/2;
    max-width: 46px;
    max-height: 30px;
    background: linear-gradient(315deg, #F28C00 0%, #D60F1B 100%);
    border-radius: 1px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    font-size: 14px;

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 45%;
      height: 45%;
      background: url('${EssoCardBlob}') no-repeat bottom right;
      background-size: cover;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 6%;
      left: 4%;
      width: 16%; //isProfileMenu ? '40px' : '7px'};
      height: 17%; // isProfileMenu ? '28px' : '5px'};
      max-width: 40px;
      max-height: 28px;
      background: url('${IconLogoEsso}') no-repeat bottom right;
      background-size: cover;
    }

    ${Theme.mediaQueries.md} {
      max-width: 40px;
      max-height: 28px;
    }
  `;
});

export const CardInfo = styled.div(({ theme }) => {
  const { font, color, mediaQueries } = theme;

  return css`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 6%;
    left: 4%;
    text-align: left;

    span {
      font-family: ${font.family.EMPrintRegular};
      line-height: 1.05;
      font-size: 3px;
      font-weight: ${font.weight.regular};
      color: ${color.white};

      ${mediaQueries.xs} {
        font-size: 2.5px;
      }

      &:last-child {
        padding-top: 3%;
        font-family: 'Ocra', serif;
      }
    }
  `;
});

export const CardDots = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    width: 3px;
    height: 5px;
    top: 6%;
    right: 4%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQueries.md} {
    }
  `;
});

export const Dots = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    position: relative;
    width: 1px;
    height: 1px;
    background: ${color.white};
    border-radius: 50%;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 1px;
      height: 1px;
      background: ${color.white};
      border-radius: 50%;
    }

    &:before {
      top: -2px;
    }

    &:after {
      top: 2px;
    }

    ${mediaQueries.md} {
    }
  `;
});
