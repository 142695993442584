import styled, { css, keyframes } from "styled-components";
import {
  ChestWinSpriteNL,
  ChestWinSpriteFR,
  ChestLostSpriteNL,
  ChestLostSpriteFR,
  Points_1000000_Sprite,
  Points_1000_Sprite,
  Points_250_Sprite,
  Points_500_Sprite
} from "../../../Phase2/assets/sprites";

const scaleOutChest = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleInPointsIOS = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
`;

const scaleInPoints = keyframes`
  from {
    transform: translate(-50%, 0) scale(1);
  }
  to {
    transform: translate(-50%, 0) scale(2);
  }
`;

export const ChestWrapper = styled.div<{ mainPrize?: boolean }>(({ theme, mainPrize = false }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    ${mediaQueries.sm} {
      position: ${mainPrize ? "relative" : "static"};
    }
  `;
});

export const ChestRow = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    //height: 50vw; //28vw;
    //max-height: 500px; //365px;
    height: 345px;
    margin: 50px auto 90px; //50px auto;

    * {
      &:focus-visible,
      &:focus {
        border: 0;
        outline: none;
      }
    }

    ${mediaQueries.sm} {
      margin: 0 auto 150px;
    }

    @media (max-width: 940px) {
      height: 38vw;
    }

    ${mediaQueries.xxs} {
      height: 56vw;
    }
  `;
});

export const ChestInner = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    height: 100%;

    ${mediaQueries.sm} {
    }
  `;
});

export const ChestBox = styled.div<{ shadow: boolean }>(({ theme, shadow }) => {
  const { mediaQueries } = theme;

  return css`
    width: 500px; //400px;
    height: 345px; //365px;
    transition: all 500ms;

    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -250px;

    &.hover {
      transform: translateX(10px);
    }

    @media (max-width: 940px) {
      width: 54vw;
      height: 38vw;
      margin-left: -27vw;
    }

    ${mediaQueries.sm} {
      width: 500px;
      height: 345px;
      transform: translateX(-50%);
      margin-left: 0;
    }

    ${mediaQueries.xxs} {
      width: 80vw;
      height: 56vw;
    }

    img {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &:focus {
        outline: none !important;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `;
});

export const ChestAnimation = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 345px;
    transform: translate(0, 0);
    transition: all 300ms;

    &.active-chest-animation-2 {
      top: 0;
      left: calc((100% - 500px * 3) / 2 + 500px + 10px);
      z-index: 13;

      &.completed-chest-animation-2 {
        transform: scale(0.8);
        left: calc((100% - 890px) / 2 + (890px / 2 - 400px) / 2 - 40px);
        top: 50%;
        margin-top: -210px;
        margin-left: 0;
      }
    }

    @media (max-width: 940px) {
      width: 54vw;
      height: 38vw;

      &.active-chest-animation-2 {
        left: calc((100% - 54vw * 3) / 2 + 54vw + 10px);

        &.completed-chest-animation-2 {
          transform: scale(0.75);
          left: -3vw;
        }
      }
    }

    ${mediaQueries.sm} {
      width: 500px;
      height: 345px;

      &.active-chest-animation-2 {
        top: 50%;
        left: 50%;
        margin-left: -250px;
        margin-top: -50%;
        animation-name: scaleIn;
        animation-duration: 800ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &.completed-chest-animation-2 {
          transform: scale(1);
          top: -20px;
          margin-top: 0;
          left: 50%;
          margin-left: -250px;
        }

        &.hide-chest-animation-mob {
          top: 30%;
          margin-top: 0;
          animation-name: opacityOut;
          animation-duration: 200ms;
        }
      }

      @keyframes scaleIn {
        from {
          transform: scale(0);
          opacity: 0;
        }
        to {
          transform: scale(0.8);
          opacity: 1;
        }
      }

      @keyframes opacityOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      &.main-prize-animation {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        //margin-left: 0;
        //animation-name: none;
      }
    }

    ${mediaQueries.xxs} {
      width: 80vw;
      height: 56vw;

      &.active-chest-animation-2 {
        margin-left: -40vw;

        &.completed-chest-animation-2 {
          margin-left: -40vw;
          top: 30px;
        }
      }

      @keyframes scaleIn {
        from {
          transform: scale(0);
          opacity: 0;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    img {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        outline: none !important;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &.scaleMainPrize {
      .chestVideo {
        animation: ${scaleOutChest} 1s forwards;
      }

      .pointsVideo {
        animation: ${scaleInPoints} 1s forwards;
      }
    }
  `;
});

const countFramesChestX = 6; // from chest_spritesheet_nl.png
const countFramesChestY = 3; // from chest_spritesheet_nl.png

const countFramesPoints1MX = 6; // from 1000000p_spritesheet.png
const countFramesPoints1MY = 2; // from 1000000p_spritesheet.png

const countFramesPoints1000X = 7; // from 1000p_spritesheet.png
const countFramesPoints1000Y = 3; // from 1000p_spritesheet.png

const countFramesPoints500X = 6; // from 500p_spritesheet.png
const countFramesPoints500Y = 3; // from 500p_spritesheet.png

const countFramesPoints250X = 6; // from 250p_spritesheet.png
const countFramesPoints250Y = 3; // from 250p_spritesheet.png

const countFramesLostX = 6; // from chest_lost_spritesheet_nl.png
const countFramesLostY = 2; // from chest_lost_spritesheet_nl.png

const imgWidth = "500px";
const imgHeight = "345px";

const imgWidthMd = "54vw";
const imgHeightMd = "54vw / (800 / 550)";

const imgWidthSm = "80vw";
const imgHeightSm = "56vw";

export const TIME_IOS_ANIMATION = 2000;

export const PointsIOS1M = styled.div<{ locale?: string }>(({ theme, locale = "nl_nl" }) => {
  // @ts-ignore
  const { mediaQueries } = theme;

  return css`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: url('${Points_1000000_Sprite}');
    background-size: calc(${imgWidth} * ${countFramesPoints1MX}) calc(${imgHeight} * ${countFramesPoints1MY});
    animation: points_1M_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints1MX - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards,
    points_1M_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints1MY - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards,
    ${scaleInPointsIOS} 1s calc(${TIME_IOS_ANIMATION}ms * 2) forwards;

    @keyframes points_1M_X {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesPoints1MX} - 1));
      }
    }

    @keyframes points_1M_Y {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesPoints1MY} - 1));
      }
    }

    @media (max-width: 940px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesPoints1MX}) calc(${imgHeightMd} * ${countFramesPoints1MY});

      @keyframes points_1M_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesPoints1MX} - 1));
        }
      }

      @keyframes points_1M_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesPoints1MY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidth};
      height: ${imgHeight};
      background-size: calc(${imgWidth} * ${countFramesPoints1MX}) calc(${imgHeight} * ${countFramesPoints1MY});

      @keyframes points_1M_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints1MX} - 1));
        }
      }

      @keyframes points_1M_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints1MY} - 1));
        }
      }
    }

    ${mediaQueries.xxs} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesPoints1MX}) calc(${imgHeightSm} * ${countFramesPoints1MY});

      @keyframes points_1M_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesPoints1MX} - 1));
        }
      }

      @keyframes points_1M_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesPoints1MY} - 1));
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: ${() => locale === "be_fr" ? `url('${ChestWinSpriteFR}')` : `url('${ChestWinSpriteNL}')`};
      background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
      animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
      chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards,
      ${scaleOutChest} 1s calc(${TIME_IOS_ANIMATION}ms * 1.5) forwards;

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
        }
      }

      @media (max-width: 940px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

        @keyframes chestX {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
          }
        }

        @keyframes chestY {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidth};
        height: ${imgHeight};
        background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY} + 1px);

        @keyframes chestX {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
          }
        }

        @keyframes chestY {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
          }
        }
      }

      ${mediaQueries.xxs} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

        @keyframes chestX {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
          }
        }

        @keyframes chestY {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS1000 = styled.div<{ locale?: string }>(({ theme, locale = "nl_nl" }) => {
  // @ts-ignore
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: ${() => locale === "be_fr" ? `url('${ChestWinSpriteFR}')` : `url('${ChestWinSpriteNL}')`};
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 940px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidth};
      height: ${imgHeight};
      background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.xxs} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_1000_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints1000X}) calc(${imgHeight} * ${countFramesPoints1000Y});
      animation: points_1000_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints1000X - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards,
      points_1000_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints1000Y - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards;

      @keyframes points_1000_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints1000X} - 1));
        }
      }

      @keyframes points_1000_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints1000Y} - 1));
        }
      }

      @media (max-width: 940px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints1000X}) calc(${imgHeightMd} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints1000Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidth};
        height: ${imgHeight};
        background-size: calc(${imgWidth} * ${countFramesPoints1000X}) calc(${imgHeight} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidth} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeight} * (${countFramesPoints1000Y} - 1));
          }
        }
      }

      ${mediaQueries.xxs} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints1000X}) calc(${imgHeightSm} * ${countFramesPoints1000Y});

        @keyframes points_1000_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints1000X} - 1));
          }
        }

        @keyframes points_1000_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints1000Y} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS500 = styled.div<{ locale?: string }>(({ theme, locale = "nl_nl" }) => {
  // @ts-ignore
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: ${() => locale === "be_fr" ? `url('${ChestWinSpriteFR}')` : `url('${ChestWinSpriteNL}')`};
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 940px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidth};
      height: ${imgHeight};
      background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.xxs} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_500_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints500X}) calc(${imgHeight} * ${countFramesPoints500Y});
      animation: points_500_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints500X - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards,
      points_500_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints500Y - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards;

      @keyframes points_500_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints500X} - 1));
        }
      }

      @keyframes points_500_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints500Y} - 1));
        }
      }

      @media (max-width: 940px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints500X}) calc(${imgHeightMd} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints500Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidth};
        height: ${imgHeight};
        background-size: calc(${imgWidth} * ${countFramesPoints500X}) calc(${imgHeight} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidth} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeight} * (${countFramesPoints500Y} - 1));
          }
        }
      }

      ${mediaQueries.xxs} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints500X}) calc(${imgHeightSm} * ${countFramesPoints500Y});

        @keyframes points_500_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints500X} - 1));
          }
        }

        @keyframes points_500_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints500Y} - 1));
          }
        }
      }
    }
  `;
});

export const PointsIOS250 = styled.div<{ locale?: string }>(({ theme, locale = "nl_nl" }) => {
  // @ts-ignore
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: ${() => locale === "be_fr" ? `url('${ChestWinSpriteFR}')` : `url('${ChestWinSpriteNL}')`};
    background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesChestX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesChestY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
      }
    }

    @media (max-width: 940px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesChestX}) calc(${imgHeightMd} * ${countFramesChestY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidth};
      height: ${imgHeight};
      background-size: calc(${imgWidth} * ${countFramesChestX}) calc(${imgHeight} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesChestY} - 1));
        }
      }
    }

    ${mediaQueries.xxs} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesChestX}) calc(${imgHeightSm} * ${countFramesChestY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesChestX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesChestY} - 1));
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: ${imgWidth};
      height: ${imgHeight};
      background-image: url('${Points_250_Sprite}');
      background-size: calc(${imgWidth} * ${countFramesPoints250X}) calc(${imgHeight} * ${countFramesPoints250Y});
      animation: points_250_X ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints250X - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards,
      points_250_Y ${TIME_IOS_ANIMATION}ms steps(${countFramesPoints250Y - 1}) calc(${TIME_IOS_ANIMATION}ms) 1 forwards;

      @keyframes points_250_X {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesPoints250X} - 1));
        }
      }

      @keyframes points_250_Y {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesPoints250Y} - 1));
        }
      }

      @media (max-width: 940px) {
        width: ${imgWidthMd};
        height: calc(${imgHeightMd});
        background-size: calc(${imgWidthMd} * ${countFramesPoints250X}) calc(${imgHeightMd} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthMd} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightMd} * (${countFramesPoints250Y} - 1));
          }
        }
      }

      ${mediaQueries.sm} {
        width: ${imgWidth};
        height: ${imgHeight};
        background-size: calc(${imgWidth} * ${countFramesPoints250X}) calc(${imgHeight} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidth} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeight} * (${countFramesPoints250Y} - 1));
          }
        }
      }

      ${mediaQueries.xxs} {
        width: ${imgWidthSm};
        height: ${imgHeightSm};
        background-size: calc(${imgWidthSm} * ${countFramesPoints250X}) calc(${imgHeightSm} * ${countFramesPoints250Y});

        @keyframes points_250_X {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: calc(-${imgWidthSm} * (${countFramesPoints250X} - 1));
          }
        }

        @keyframes points_250_Y {
          0% {
            background-position-y: 0;
          }
          100% {
            background-position-y: calc(-${imgHeightSm} * (${countFramesPoints250Y} - 1));
          }
        }
      }
    }
  `;
});

export const LostIOS = styled.div<{ locale?: string }>(({ theme, locale = "nl_nl" }) => {
  // @ts-ignore
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${imgWidth};
    height: ${imgHeight};
    background-image: ${() => locale === "be_fr" ? `url('${ChestLostSpriteFR}')` : `url('${ChestLostSpriteNL}')`};
    background-size: calc(${imgWidth} * ${countFramesLostX}) calc(${imgHeight} * ${countFramesLostY});
    animation: chestX ${TIME_IOS_ANIMATION}ms steps(${countFramesLostX - 1}) 1 forwards,
    chestY ${TIME_IOS_ANIMATION}ms steps(${countFramesLostY - 1}) 1 forwards;

    @keyframes chestX {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: calc(-${imgWidth} * (${countFramesLostX} - 1));
      }
    }

    @keyframes chestY {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: calc(-${imgHeight} * (${countFramesLostY} - 1));
      }
    }

    @media (max-width: 940px) {
      width: ${imgWidthMd};
      height: calc(${imgHeightMd});
      background-size: calc(${imgWidthMd} * ${countFramesLostX}) calc(${imgHeightMd} * ${countFramesLostY});

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthMd} * (${countFramesLostX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightMd} * (${countFramesLostY} - 1));
        }
      }
    }

    ${mediaQueries.sm} {
      width: ${imgWidth};
      height: ${imgHeight};
      background-size: calc(${imgWidth} * ${countFramesLostX}) calc(${imgHeight} * ${countFramesLostY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidth} * (${countFramesLostX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeight} * (${countFramesLostY} - 1));
        }
      }
    }

    ${mediaQueries.xxs} {
      width: ${imgWidthSm};
      height: ${imgHeightSm};
      background-size: calc(${imgWidthSm} * ${countFramesLostX}) calc(${imgHeightSm} * ${countFramesLostY} + 1px);

      @keyframes chestX {
        0% {
          background-position-x: 0;
        }
        100% {
          background-position-x: calc(-${imgWidthSm} * (${countFramesLostX} - 1));
        }
      }

      @keyframes chestY {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: calc(-${imgHeightSm} * (${countFramesLostY} - 1));
        }
      }
    }
  `;
});
