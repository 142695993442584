export const cardsStyles = [
  {
    id: 1,
    lg: {
      width: '71%',
      height: '55.7%',
      top: '-7.2%',
      left: '53%',
      rotate: '10deg'
    },
    md: {
      width: '71%',
      height: '55.7%',
      top: '10.8%',
      left: '58%',
      rotate: '-12deg'
    },
  },
  {
    id: 2,
    lg: {
      width: '63%',
      height: '49%',
      top: '68.5%',
      left: '52%',
      rotate: '-10deg'
    },
    md: {
      width: '70%',
      height: '54%',
      top: '26%',
      left: '-16%',
      rotate: '7deg'
    },
  },
  {
    id: 3,
    lg: {
      width: '66%',
      height: '51%',
      top: '25%',
      left: '-30%',
      rotate: '7deg'
    },
    md: {
      width: '66%',
      height: '51%',
      top: '25%',
      left: '-30%',
      rotate: '7deg'
    },
  }
]