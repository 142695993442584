import styled, { css } from 'styled-components';
import { ButtonProps } from './Button';
import { Theme } from '../../../theme';
import {
  IconArrowAdmiralRight,
  IconArrowNavyRight,
  IconArrowRight
} from '../../../../prepr/assets';

const BUTTON_TYPE: { [key: string]: any } = {
  primary: css`
    color: ${Theme.color.white};
    background-color: ${Theme.color.admiral};
    text-decoration: none;

    span {
      color: ${Theme.color.white};
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${Theme.color.cloudWhite};
      color: ${Theme.color.grey};
      border-color: ${Theme.color.cloudWhite};

      span {
        color: ${Theme.color.grey};
      }

      &:hover {
        cursor: not-allowed;
        background-color: ${Theme.color.cloudWhite};
        color: ${Theme.color.grey};
        border-color: ${Theme.color.cloudWhite};
      }
    }
  `,
  secondary: css`
    color: ${Theme.color.admiral};
    background-color: ${Theme.color.lightblue};
    border-color: ${Theme.color.lightblue};

    span {
      color: ${Theme.color.admiral};
    }

    &:hover {
      background-color: ${Theme.color.steelBlue};
      border-color: ${Theme.color.steelBlue};
    }

    &:focus,
    &:focus-visible {
      background-color: ${Theme.color.steelBlue};
      border-color: ${Theme.color.steelBlue};
      outline: 2px solid ${Theme.color.azure};

      span {
        color: ${Theme.color.admiral};
      }
    }

    &:active {
      background-color: ${Theme.color.admiral};
      outline: none;

      span {
        color: ${Theme.color.white};
      }
    }
  `,
  tertiary: css`
    position: relative;
    display: inline-block;
    padding-right: 25px;
    line-height: 1;
    color: ${Theme.color.blue};
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -1px;
      background: url(${IconArrowRight}) center no-repeat;
      background-size: contain;
      width: 14px;
      height: 16px;
    }

    &:active,
    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: ${Theme.color.navy};
      transition: all 0.2s ease-in-out;

      &:after {
        background-image: url(${IconArrowAdmiralRight});
      }
    }

    &:hover,
    &:focus {
      color: ${Theme.color.navy};
      transition: all 0.3s ease-in-out;

      &:after {
        background-image: url(${IconArrowNavyRight});
      }
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid ${Theme.color.azure};
    }
  `,
  tangerine: css`
    color: ${Theme.color.white};
    background-color: ${Theme.color.tangerine};
    border-color: ${Theme.color.tangerine};

    span {
      color: ${Theme.color.white};
    }

    &:hover {
      background-color: ${Theme.color.tangerine};
      border-color: ${Theme.color.tangerine};
      filter: brightness(90%);
    }

    &:focus,
    &:focus-visible {
      background-color: ${Theme.color.tangerine};
      border-color: ${Theme.color.tangerine};
      outline: 2px solid ${Theme.color.tangerine};
      filter: brightness(90%);

      span {
        color: ${Theme.color.white};
      }
    }

    &:active {
      background-color: ${Theme.color.tangerine};
      outline: none;

      span {
        color: ${Theme.color.white};
      }
    }
  `,
};

const setCustomStyles = (backgroundcolor: any, textcolor: any) => {
  return css `
    background-color: ${backgroundcolor};
    border-color: ${backgroundcolor};

    span {
      color: ${textcolor};
    }
    
    &:hover,
    &:active,
    &:focus,
    &:focus-visible{
      background-color: ${backgroundcolor};
      border-color: ${backgroundcolor};
      filter: brightness(90%);

      span {
        color: ${textcolor};
      }
    }
  `
}

const DISABLED = css`
  cursor: not-allowed;
  background-color: ${Theme.color.cloudWhite};
  color: ${Theme.color.grey};
  border-color: ${Theme.color.cloudWhite};

  span {
    color: ${Theme.color.grey};
  }

  &:hover {
    cursor: not-allowed;
    // background-color: ${Theme.color.cloudWhite};
    // color: ${Theme.color.grey};
    // border-color: ${Theme.color.cloudWhite};
  }
`;

export const ButtonWrapper = styled.button<ButtonProps>(
  ({ theme, disabled, buttontype, backgroundcolor, textcolor }) => {
    const { color, size, font, mediaQueries } = theme;

    return css`
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${size.px14};
      border-radius: ${size.px4};
      border: none;

      font-family: ${font.family.EMPrintSemi};
      font-weight: 600;
      text-align: center;

      min-width: 158px;
      height: 48px;

      padding: ${size.px16} ${size.px32};
      margin-left: auto;
      margin-right: auto;

      span {
        font-family: ${font.family.EMPrintSemi};
        display: block;
        line-height: 1;
      }

      &:hover {
        background-color: ${color.olympic};
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      &:focus,
      &:focus-visible {
        background-color: ${color.olympic};
        outline: 2px solid ${color.azure};
        transition: all 0.2s ease-in-out;
      }

      &:active {
        background-color: ${color.navy};
        outline: none;
        transition: all 0.2s ease-in-out;

        span {
          color: ${color.white};
        }
      }

      ${disabled && DISABLED}
      
      ${buttontype && BUTTON_TYPE[buttontype]};
      
      ${setCustomStyles(backgroundcolor, textcolor)}
      
      ${mediaQueries.sm} {
        width: 100%;
        justify-content: center;
      }
    `;
  }
);
