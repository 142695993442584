import React, {useEffect, useCallback, useState} from 'react';
import {PageHeaderHomeProps} from './pageHeaderHome.types';
import {HeroBanner, AnchorButton} from '../../../redesign/components/shared';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {EffectFade, Autoplay} from 'swiper';
import {useLoader, useNotification} from '../../../redesign/hooks';
import {checkLoggedIn} from '../../../components/utils/_app-helpers';
import {checkSegmentedCustomerGroup} from '../../../components/utils/_campaign-helpers';
import {HeroBannerProps} from '../../../redesign/components/shared/HeroBanner/heroBanner.types';
import {PageHeaderHomeWrapper} from './PageHeaderHome.styles';

SwiperCore.use([Autoplay, EffectFade]);

const PageHeaderHome: React.FC<PageHeaderHomeProps> = ({params}) => {
  const {showNotification, notificationComponent} = useNotification({
    toastType: 'error',
    positionTop: '30px',
    width: 'auto'
  });
  const headerBanners = params?.header || [];
  const [banners, setBanners] = useState<HeroBannerProps[] | null>(null);

  const isLoggedIn = checkLoggedIn();
  const authToken = localStorage.getItem('authToken');
  const getToken = authToken && JSON.parse(authToken);
  const token = getToken && getToken.accessToken;

  const loader = useLoader(banners === null);

  const showNonSegmentedBanners = useCallback(() => {
    setBanners(headerBanners?.filter((banner: HeroBannerProps) => !banner?.is_banner_for_segmented_users) || []);
  }, [headerBanners]);

  const showAllBanners = useCallback(() => {
    setBanners(headerBanners);
  }, [headerBanners]);

  const handleCheckSegmentedCustomerGroup = useCallback(async () => {
    try {
      const isVisible = await checkSegmentedCustomerGroup(token);
      isVisible?.data?.result ? showAllBanners() : showNonSegmentedBanners();
    } catch (error) {
      showNonSegmentedBanners();
      showNotification('Error checking segmented customer group');
    }
  }, [token, showAllBanners, showNonSegmentedBanners, showNotification]);

  useEffect(() => {
    if (isLoggedIn && headerBanners?.some((banner: HeroBannerProps) => banner?.is_banner_for_segmented_users)) {
      handleCheckSegmentedCustomerGroup();
    } else {
      showNonSegmentedBanners();
    }
  }, [isLoggedIn, handleCheckSegmentedCustomerGroup, headerBanners, showNonSegmentedBanners]);

  return (
    loader ||
    (!!banners?.length ?
      <PageHeaderHomeWrapper>
        <Swiper
          slidesPerView={1}
          speed={700}
          loop={banners?.length > 1}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false
          }}
        >
          {banners?.map(
            (
              {text, visual, visual_mobile, cta_label, cta_link, headline, superheadline, cta_type, additional_content}: any,
              index: { toString: () => React.Key | null | undefined }
            ) => {
              const tab = {
                text: text,
                visual: visual,
                visual_mobile: visual_mobile,
                cta_label: cta_label,
                cta_link: cta_link,
                headline: headline,
                superheadline: superheadline,
                cta_type: cta_type || 'primary',
                additional_content: additional_content || '',
              };
              return (
                <SwiperSlide key={index.toString()}>
                  <HeroBanner {...tab} />
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
        <AnchorButton className='anchor-cta' anchorID='sparen'/>
        {notificationComponent}
      </PageHeaderHomeWrapper>
      : null)
  );
};

export default PageHeaderHome;