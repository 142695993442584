import React, { useEffect, useState } from 'react';
import i18n from '../../../../../../../config/i18n';
import { isWebView } from '../../../../../../../components/utils/_app-helpers';
import { shopLang } from '../../../../../../../config/config';
import { InfoText, ShareBtn, ShareOptions, ShareWrapper, ToggleShare } from './ShareButton.styles';
import { CloseComponent, ShareComponent, ShareURLComponent, WhatsappComponent } from '../../assets';

const ShareButtons = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');
  const webView = isWebView();
  const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);
  const isAndroid = /android/.test(window.navigator.userAgent.toLowerCase());

  const shareUrl = process.env.REACT_APP_ENV === 'prod' ? (
    shopLang !== 'nl_nl' ?
      `https://www.essoextras.be/#/${i18n.t('mc_route_path')}` :
      `https://www.essoextras.nl/#/${i18n.t('mc_route_path')}`
  ) : (
    shopLang !== 'nl_nl' ?
      `https://www.test-esso-extra.be/#/${i18n.t('mc_route_path')}` :
      `https://www.test-esso-extra.nl/#/${i18n.t('mc_route_path')}`
  );

  const handleCopyLink = async () => {
    try {
      await navigator?.clipboard?.writeText(shareUrl);
      setCopyMessage('Link copied');
    } catch (err) {
      setCopyMessage('Link not copied');
      console.error(err);
    }
  };

  const openWhatsApp = () => {
    const shareText = encodeURIComponent(shareUrl ?? '');
    const webLink = (isIOS || isAndroid) ?
      `https://api.whatsapp.com/send?text=${shareText}`:
      `https://web.whatsapp.com/send?text=${shareText}`;

    window.open(webLink, '_blank');
  };

  const share = () =>
    navigator.share({
      url: shareUrl
    }).catch((error) => console.error(error));

  useEffect(() => {
    if (copyMessage) {
      setTimeout(() => setCopyMessage(''), 1000)
    }
  }, [copyMessage]);

  return (
    <ShareWrapper>
      <ToggleShare isOpen={isOpen} onClick={() => {
        setIsOpen(!isOpen)
      }}>
        {isOpen ? <CloseComponent/> : <ShareComponent/>}
        <span>{i18n.t('mc_share_button')}</span>
      </ToggleShare>

      <ShareOptions isOpen={isOpen}>
        {webView ?
          (navigator?.canShare?.({ url: shareUrl}) &&
            <ShareBtn onClick={share}>
              <WhatsappComponent/>
            </ShareBtn>
          ) :
          <ShareBtn onClick={openWhatsApp}>
            <WhatsappComponent/>
          </ShareBtn>
        }
        <ShareBtn onClick={handleCopyLink}>
          {copyMessage && <InfoText>{copyMessage}</InfoText>}
          <ShareURLComponent/>
        </ShareBtn>
      </ShareOptions>
    </ShareWrapper>
  );
};

export default ShareButtons;