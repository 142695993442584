export namespace Theme {
  enum Colors {
    white = '#FFFFFF',
    grey = '#999999',
    cloudWhite = '#F9F9F9',
    chineseGray = '#CCCCCC',
    'grey-100' = '#E5E5E5',
    'grey-200' = '#E1E1E1',
    'grey-300' = '#666666',
    'grey-400' = '#333333',
    darkGrey = '#202020',
    black = '#000000',

    blue = '#003399',
    cyanBlue = '#0258C6',
    admiral = '#003399',
    navy = '#002164',
    olympic = '#587ABC',
    lightblue = '#F2F5FA',
    azure = '#00aeff',
    dodgerBlue = '#0069FF',
    red = '#DB0007',
    brightRed = '#FF303E',
    lightRed = '#FF6868',
    orange = '#FF960C',
    tangerine='#F37427',
    green = '#35CE54',
    darkCharcoal = '#333',
    graniteGray = '#666',
    lightBlue = '#F2F5FA',
    steelBlue = '#B2C1E0',
    sea = '#007DDE',
    cottonGrey = '#EBEBEB',
  }

  enum Aliases {
    disabled = Colors['grey-100'],
    border = Colors['grey-200'],
    text = Colors['grey-400'],
    paragraph = Colors['grey-300'],
    link = Colors.blue,
    error = Colors.red
  }

  export const color = {
    ...Aliases,
    ...Colors
  };

  export const font = {
    family: {
      sansSerif: 'Arial',
      EMPrintSemi: 'EMprint W04 Semibold',
      EMPrintRegular: 'EMprint W04 Regular'
    },

    style: {
      normal: 'normal',
      italic: 'italic'
    },

    weight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      black: 900
    }
  };

  export const size = {
    px96: '6rem',
    px64: '4rem',
    px60: '3.75rem',
    px56: '3.5rem',
    px55: '3.438rem',
    px50: '3.125rem',
    px48: '3rem',
    px42: '2.625rem',
    px40: '2.5rem',
    px36: '2.25rem',
    px34: '2.125rem',
    px32: '2rem',
    px30: '1.875rem',
    px28: '1.75rem',
    px26: '1.625rem',
    px24: '1.5rem',
    px23: '1.4375rem',
    px22: '1.375rem',
    px20: '1.25rem',
    px18: '1.125rem',
    px16: '1rem',
    px15: '0.938rem',
    px14: '0.875rem',
    px13: '0.8125rem',
    px12: '0.75rem',
    px10: '0.625rem',
    px8: '0.5rem',
    px4: '0.25rem'
  };

  enum Breakpoints {
    s = '380px',
    xs = '480px',
    xxs = '600px',
    sm = '767px',
    md = '1024px',
    lg = '1200px',
    xl = '1440px',
    xxl = '1600px',
  }

  export const mediaQueries = {
    se: `@media (max-width: 374px)`,
    s: `@media (max-width: ${Breakpoints.s})`,
    xs: `@media (max-width: ${Breakpoints.xs})`,
    xxs: `@media (max-width: ${Breakpoints.xxs})`,
    sm: `@media (max-width: ${Breakpoints.sm})`,
    md: `@media (max-width: ${Breakpoints.md})`,
    lg: `@media (max-width: ${Breakpoints.lg})`,
    xl: `@media (max-width: ${Breakpoints.xl})`,
    xxl: `@media (max-width: ${Breakpoints.xxl})`,

    minWidthSM: `@media (min-width: ${Breakpoints.sm})`,
    retinaScreens: `@media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (min-resolution: 192dpi)`,
    nonRetinaScreens: `@media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1)`
  };
}
