import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../../config/i18n';
import { logOut } from '../../utils';
import { useWindowSize } from '../../hooks';
import { checkLoggedIn } from '../../../components/utils/_app-helpers';
import { Button, ProfileMenu } from '../shared';
import { ProfileBanner } from '../shared';
import { IconLogoutBlueComponent } from '../../assets';
import { Icon, Text, ButtonCustom } from '../shared/ProfileMenu/ProfileMenu.styles';
import { WrapperLogged, WrapperUnlogged } from './HeaderProfileMenu.styles';

const HeaderProfileMenu = () => {
  const isLoggedIn = checkLoggedIn();
  const windowSize = useWindowSize();

  return (
    isLoggedIn ?
      <WrapperLogged>
        {windowSize <= 1024 && <ProfileBanner />}
        <ProfileMenu layout={'header'} />
        <ButtonCustom onClick={logOut}>
          <Icon><IconLogoutBlueComponent /></Icon>
          <Text>{i18n.t('06-Submenu_link_6')}</Text>
        </ButtonCustom>
      </WrapperLogged>
      :
      <WrapperUnlogged>
        <Button url={{ pathname: '/auth/login', state: { prevPath: '/profile/overview' } }}
                text={i18n.t('btn_loggedIn')} />
        <p>
          {i18n.t('profile_menu_unlogged_text')}&nbsp;
          <Link to={'/auth/registration/?regOption=3'}>{i18n.t('profile_menu_unlogged_link')}</Link>
        </p>
      </WrapperUnlogged>
  );
};

export default HeaderProfileMenu;
