const anySymbolExceptSpaceEmptyString = /^(?!\s*$).+/;
export const regExpList= {
  email: {
    required: anySymbolExceptSpaceEmptyString,
  },
  firstName: {
    required: anySymbolExceptSpaceEmptyString,
  },
  lastName: {
    required: anySymbolExceptSpaceEmptyString,
  },
  phoneNumber: {
    nullStarted: /^(?:[1-9]\d*)/,
    onlyNumbers: /^\d+$/,
    minMaxLength: /^\d{7,14}$/,
  },
  cardNumber: {
    format: /^[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{3}$/,
  },
  cardCode: {
    required: anySymbolExceptSpaceEmptyString,
  },
  password:{
    uppercase: /(.*[A-Z].*)/,
    lowercase: /(.*[a-z].*)/,
    number: /(.*\d.*)/,
    specialCharacter: /[-@#!$%^&*()_+|~=`{}\\\[\]:';'<>?,./]/, // eslint-disable-line
    eightCharacter: /(?=.{8,})/,
    match: null,
  },
  birthday: {
    format: /^(\d{2})\/(\d{2})\/(\d{4})$|^$/
  },
  accessCode: {
    required: anySymbolExceptSpaceEmptyString,
  }
}