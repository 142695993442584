/*
 * action types
 */

export const ADD_USER_DATA = 'ADD_USER_DATA';
export const ADD_CMS_DATA = 'ADD_CMS_DATA';
export const ADD_POINTS_HISTORY = 'ADD_POINTS_HISTORY';
export const ADD_LATEST_POINTS_HISTORY = 'ADD_LATEST_POINTS_HISTORY';
export const ADD_CARDS_DATA = 'ADD_CARDS_DATA';
export const ADD_ORDER_HISTORY = 'ADD_ORDER_HISTORY';
export const ADD_TRANSACTION_TYPES = 'ADD_TRANSACTION_TYPES';
export const ADD_ORDER_CAT = 'ADD_ORDER_CAT';
export const ADD_CUSTOMER_DETAILS = 'ADD_CUSTOMER_DETAILS';
export const ADD_WEBVIEW = 'ADD_WEBVIEW';
export const ADD_NOTICE = 'ADD_INTERCEPTED_ERROR';
export const SET_POINTS_TRANSFER = 'SET_POINTS_TRANSFER';
export const HISTORY_LOADED = 'HISTORY_LOADED';
export const PHONE_NUMBER_LOADED = 'PHONE_NUMBER_LOADED';
export const IS_CURRENT_USER_DATA_LOADED = 'IS_CURRENT_USER_DATA_LOADED';
export const IS_CURRENT_USER_ACTIVE = 'IS_CURRENT_USER_ACTIVE';
export const USER_REWARDS_LOADED = 'USER_REWARDS_LOADED';
export const SET_PROFILE_MENU_LINKS = 'SET_PROFILE_MENU_LINKS';
export const GET_GIFT_CARDS = 'GET_GIFT_CARDS';

/*
 * action creators
 */

export function setAllPointsTransfer(data) {
  return { type: SET_POINTS_TRANSFER, data };
}
export function addUserData(data) {
  return { type: ADD_USER_DATA, data };
}

export function addCMSData(data) {
  return { type: ADD_CMS_DATA, data };
}

export function addPointsHistory(data) {
  return { type: ADD_POINTS_HISTORY, data };
}

export function addLatestPointsHistory(data) {
  return { type: ADD_LATEST_POINTS_HISTORY, data };
}

export function addCardsData(data) {
  return { type: ADD_CARDS_DATA, data };
}

export function addOrderHitory(data) {
  return { type: ADD_ORDER_HISTORY, data };
}

export function addTransactionTypes(data) {
  return { type: ADD_TRANSACTION_TYPES, data };
}

export function addOrderCategories(data) {
  return { type: ADD_ORDER_CAT, data };
}

export function addCustomerDetails(data) {
  return { type: ADD_CUSTOMER_DETAILS, data };
}
export function addWebView(data) {
  return { type: ADD_WEBVIEW, data };
}

export function addNotice(data) {
  return { type: ADD_NOTICE, data };
}

export function historyLoadedState(data) {
  return { type: HISTORY_LOADED, data };
}

export function phoneNumberLoaded(data) {
  return { type: PHONE_NUMBER_LOADED, data };
}

export function setIsCurrentUserDataLoaded(data) {
  return { type: IS_CURRENT_USER_DATA_LOADED, data };
}

export function setIsCurrentUserActive(data) {
  return { type: IS_CURRENT_USER_ACTIVE, data };
}

export function setUserRewardsLoaded(data) {
  return { type: USER_REWARDS_LOADED, data };
}

export function setProfileMenuLinks(data) {
  return { type: SET_PROFILE_MENU_LINKS, data };
}

export function getGiftCards(data) {
  return { type: GET_GIFT_CARDS, data };
}