import styled, { css } from 'styled-components';

type ModalBoxType = {
  maxWidth: string;
}

export const ModalOverlay = styled.div(() => {
  return css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
  `;
})

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

export const ModalBox = styled.div<ModalBoxType>(({ theme, maxWidth }) => {
  const { color, size } = theme;

  return css`
    position: relative;
    max-width: ${maxWidth};
    max-height: 90vh;
    overflow-y: auto;
    width: 94%;
    text-align: left;
    box-sizing: border-box;
    z-index: 100;
    background: ${color.white};
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 30px;
    
    &.QRCodeModalBox{
      user-select: none;
      background: linear-gradient(315deg, #007DDE 0%, #0047BA 100%);
      
      ${ModalCloseBtn}{
        &:before,
        &:after {
          background: ${color.white};
        }
      }
      
      ${ModalTitle}{
        color: ${color.white};
        font-size: ${size.px18};
        margin-bottom: 5px;
      }
    }
    
    &.changePasswordModal{
      ${ModalTitle}{
        margin-bottom: ${size.px16};
      }

      ${ModalText}{
        font-size: ${size.px16};
        margin-bottom: ${size.px16};
      }
      
      button{
        min-width: auto;
        margin-right: 0;
      }
    }
  `;
});

export const ModalCloseBtn = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -1px;
      height: 16px;
      width: 2px;
      background: ${color.blue};
    }
    
    &:before{
      transform: rotate(45deg);
    }
    
    &:after {
      transform: rotate(-45deg);
    }
  `;
});

export const ModalTitle = styled.h3(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px24};
    line-height: 1.2;
    color: ${color.darkCharcoal};
    margin-bottom: 30px;
  `;
});

export const ModalContent = styled.div(() => {
  return css`
    margin-bottom: 30px;
  `;
});

export const ModalSubTitle = styled.h6(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px16};
    line-height: 1.5;
    color: ${color.darkCharcoal};
    margin-bottom: 15px;
  `;
});

const setTextStyles = (theme: any) =>  css`
  font-family: ${theme.font.family.sansSerif};
  font-weight: ${theme.font.weight.regular};
  font-size: ${theme.size.px14};
  line-height: 1.57;
  color: ${theme.color.graniteGray};
  margin-bottom: 0;
`

export const ModalText = styled.p(({ theme }) => {
  return css`
    ${setTextStyles(theme)};
    
    span{
      ${setTextStyles(theme)};
    }
  `;
});

export const ButtonsGroup = styled.div(() => {
  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 -10px;
    
    a, button{
      min-width: auto;
      margin: 0 10px 10px;
      text-decoration: none;
    }
  `;
});

export const ButtonFullWidth = styled.div(() => {
  return css`
    a, button{
      width: 100%;
    }
  `;
});