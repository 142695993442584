import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import i18n from '../../../../../config/i18n';
import { useWindowSize } from '../../../../../redesign/hooks';
import Toast from '../Toast';
import { KeyBlue, KeyGold } from '../../../../../prepr/components/EndYearCampaignCustom/2024/assets';
import { Box, KeysSeparator, KeysText, KeysWrapper } from './Keys.styles';

const Keys = ({ phase, keysBlue, keysGold, accountBalance, isVisible = true }: {
  phase: number, keysBlue?: number, keysGold: number, accountBalance?: string, isVisible?: boolean
}) => {
  const windowSize = useWindowSize();
  const isDefineWinnerCompleted = useSelector((state: any) => state?.isDefineWinnerCompletedEYC);
  const isReplayGame = useSelector((state: any) => state?.isReplayGameEYC);
  const goldKeysRef = useRef<HTMLDivElement>(null);
  const [isInfoChestShow, setIsInfoChestShow] = useState(false);

  useEffect(() => {
    // if(isLoggedIn && !isFinished && windowSizeSize <= 768){
    if (windowSize <= 768) {
      const handleClickOutside = (e: any) => {
        const goldKeysTarget = goldKeysRef?.current?.contains(e.target);
        if (!goldKeysTarget) {
          setIsInfoChestShow(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [windowSize]);

  const ToastEl = <>
    <CSSTransition
      in={!keysBlue && isInfoChestShow}
      timeout={{
        appear: 1000,
        enter: 1000,
        exit: 300
      }}
      classNames='anim'
      unmountOnExit
    >
      <Toast type={'keys'}
             message={i18n.t('eyc_2024_1_hover_golden_key')} />
    </CSSTransition>
  </>;


  return (
    phase === 1 ? (
      windowSize >= 768 ?
        <>
          <KeysWrapper>
            <img src={KeyBlue} alt='key' />
            <KeysText>
              {keysBlue}
              <KeysSeparator />
              {accountBalance}&nbsp;{i18n.t('eyc_2024_1_points')}
            </KeysText>
          </KeysWrapper>
          <KeysWrapper style={!keysBlue ? { cursor: 'pointer' } : { cursor: 'default' }}
                       onMouseEnter={() => windowSize >= 768 && !keysBlue && (isDefineWinnerCompleted || !isReplayGame) && setIsInfoChestShow(true)}
                       onMouseLeave={() => windowSize >= 768 && !keysBlue && (isDefineWinnerCompleted || !isReplayGame) && setIsInfoChestShow(false)}
          >
            <img src={KeyGold} alt='key' />
            <KeysText>{keysGold}</KeysText>
            {ToastEl}
          </KeysWrapper>
        </> :
        <KeysWrapper>
          <img src={KeyBlue} alt='key' />
          <KeysText>
            {keysBlue}
            <KeysSeparator />
          </KeysText>
          <Box
            ref={goldKeysRef}
            style={!keysBlue ? { cursor: 'pointer' } : { cursor: 'default' }}
            onClick={() => {
              if (!keysBlue && windowSize < 768) {
                !isInfoChestShow ? setIsInfoChestShow(true) : setIsInfoChestShow(false);
              }
            }}>
            <img src={KeyGold} alt='key' />
            <KeysText>
              {keysGold}
              <KeysSeparator />
            </KeysText>
          </Box>
          <KeysText>
            {accountBalance}&nbsp;{i18n.t('eyc_2024_1_points')}
          </KeysText>
          {ToastEl}
        </KeysWrapper>
    ) : (
      <KeysWrapper isVisible={isVisible}>
        <img src={KeyGold} alt='key' />
        <KeysText>{keysGold}</KeysText>
      </KeysWrapper>
    )
  );
};

export default Keys;