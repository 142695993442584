import styled, {css} from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';
import {LotteryBoxBase} from "../../../MiniGames.styles";
import {FestivalCard, SetBE} from "../../assets";

export const LotteryBox = styled(LotteryBoxBase)`

  ${Theme.mediaQueries.md} {
  }
`;

export const LotteryContent = styled.div`
  position: relative;
  margin: 0 auto;
  width: 500px;
  height: 425px;

  ${Theme.mediaQueries.xl} {
    width: 35vw;
    height: 30vw;
  }

  ${Theme.mediaQueries.md} {
    width: 500px;
    height: 425px;
  }

  ${Theme.mediaQueries.xxs} {
    width: 86vw;
    height: 75vw;
  }
`;

export const CardsGroup = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  
  ${Theme.mediaQueries.md} {
  }
`;

type CardSizeProps = {
  width: string;
  height: string;
  top: string,
  left: string,
  rotate: string,
}

type CardProps = {
  lg: CardSizeProps,
  md: CardSizeProps,
};

export const Card = styled.div<CardProps>(({lg, md}) => {
  return css`
    width: ${lg.width};
    height: ${lg.height};
    top: ${lg.top};
    left: ${lg.left};
    transform: rotate(${lg.rotate});
    position: absolute;
    background: url('${FestivalCard}') no-repeat;
    background-size: cover;

    ${Theme.mediaQueries.md} {
      width: ${md.width};
      height: ${md.height};
      top: ${md.top};
      left: ${md.left};
      transform: rotate(${md.rotate});

      &:last-child {
        display: none;
      }
    }
  `;
});

export const Set = styled.div(() => {
  return css`
    width: 82%;
    height: 77%;
    top: 59%;
    left: -32%;
    position: absolute;
    background: url('${SetBE}') no-repeat;
    background-size: cover;
    z-index: 3;

    ${Theme.mediaQueries.md} {
      width: 40%;
      height: 38%;
      top: 70%;
      left: 1%;
    }
  `;
});
