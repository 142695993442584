import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Button } from '../../../../../../../redesign/components/shared';
import Timer from '../Timer';
import Form from '../Form';
import ModalBE from '../ModalBE';
import i18n from '../../../../../../../config/i18n';
import APIMiniGamesService from '../../../api/api';
import { shopLang } from '../../../../../../../config/config';
import { handoverConstructor } from '../../../../../../../components/utils/_app-helpers';
import { handleHandoverForm } from '../../../../../../../redesign/utils';
import { Title, Text, Note } from '../../../MiniGames.styles';
import { ButtonShop, ContentBox, FloatBox, InfoBox } from './Info.styles';
import { LogoBEComponent, LogoFRComponent, LogoNLComponent } from '../../assets';

const Info = ({ isUserLoggedIn, isFinalWeek }: { isUserLoggedIn: boolean, isFinalWeek: boolean }) => {
  const location = useLocation();
  const isShowModal = useSelector((state: any) => state?.isShowModalCampaign);
  const userData = useSelector((state: any) => state?.userData);
  const isUserWinner = useSelector((state: any) => state?.isUserWonCampaign);
  const isReplayGame = useSelector((state: any) => state?.isReplayGame);
  const isFormShowed = useSelector((state: any) => state?.isFormShowed);
  const participantId = useSelector((state: any) => state?.participantData?.id);
  const isPrizePhysical = useSelector((state: any) => state?.participantData?.lotteryPrize?.prize?.isPhysical);
  const winnerAddress = useSelector((state: any) => state?.participantData?.address);
  const activeLotteryId = useSelector((state: any) => state?.campaignInfo?.activeLottery?.id);

  const endTimeActiveLottery = useSelector((state: any) => state?.campaignInfo?.activeLottery?.endTime);
  const endDate = new Date(endTimeActiveLottery);
  const endTimeInMs = endDate.getTime();
  const leftTime = endTimeInMs - Date.now();

  const formRef = useRef<HTMLFormElement>(null);
  const shopUrl = 'https://www.essoextras.be/shop/decathlon-benelux-cadeaukaarten.html';
  const rewardLink = shopUrl.replace(
    /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?/,
    ""
  );
  const authToken = localStorage.getItem('authToken');
  const authTokenParse = authToken && JSON.parse(authToken);
  const accessToken = authTokenParse && authTokenParse.accessToken;

  useEffect(() => {
    if (shopLang !== 'nl_nl' && isUserWinner === true) {
      if (userData) {
        const activeUserLottery = isFinalWeek ?
          userData?.campaignGameStats?.find((lottery: any) => lottery?.campaignGameSlug?.replaceAll(' ', '')?.includes('week-4')) :
          userData?.campaignGameStats?.find((lottery: any) => lottery.campaignGameId === activeLotteryId);

        const userHasAlreadyWon = activeUserLottery?.winners?.length > 0;

        const participant = userHasAlreadyWon && activeUserLottery?.winners[0];
        participant && APIMiniGamesService.setParticipant(participant);

        if (participantId && isPrizePhysical && !winnerAddress) {
          APIMiniGamesService.setIsFormShowed(true);
        }
      }
    }
  }, [isUserWinner, userData, isFinalWeek, activeLotteryId, participantId, isPrizePhysical, winnerAddress]);

  const setButton = (text: string) => {
    return shopLang === 'nl_nl' ?
      <Button backgroundcolor={'#002164'}
              textcolor={'#fff'}
              text={text}
              url={{ pathname: '/rewards/all', state: { prevPath: location.pathname } }} /> :
      <ButtonShop
        method='POST'
        action={handoverConstructor(rewardLink)}
        ref={formRef}
        onClick={() => handleHandoverForm(formRef, shopUrl, false)}>
        <input type='text' name='auth_key' value={accessToken} hidden readOnly />
        <span>{text}</span>
      </ButtonShop>;
  };

  return (
    <>
      {shopLang !== 'nl_nl' && isShowModal && <ModalBE />}

      {shopLang !== 'nl_nl' && isFormShowed && <Form visible={isFormShowed} />}

      <InfoBox>
        {shopLang === 'nl_nl' ? <LogoNLComponent /> :
          shopLang === 'be_nl' ? <LogoBEComponent /> :
            <LogoFRComponent />
        }
        <CSSTransition
          in={isUserWinner === null}
          timeout={{ appear: 2000, enter: 2000, exit: 0 }}
          classNames='anim'
          unmountOnExit
        >
          <ContentBox increased={shopLang !== 'nl_nl'}>
            <Title dangerouslySetInnerHTML={{ __html: i18n.t('mc_default_title') }} />
            <Text dangerouslySetInnerHTML={{ __html: i18n.t('mc_default_text') }} />

            {isUserLoggedIn &&
              <FloatBox>
                <Note dangerouslySetInnerHTML={{ __html: i18n.t('mc_default_note') }} />
              </FloatBox>
            }

            {!isUserLoggedIn &&
              <Button backgroundcolor={'#F37427'}
                      textcolor={'#fff'}
                      text={i18n.t('mc_login_button')}
                      url={{ pathname: '/auth/login', state: { prevPath: location.pathname } }} />
            }
          </ContentBox>
        </CSSTransition>

        <CSSTransition
          in={isUserWinner === false && !isFinalWeek}
          timeout={{ appear: 2000, enter: 2000, exit: 0 }}
          classNames='anim'
          unmountOnExit
        >
          <ContentBox>
            <Title dangerouslySetInnerHTML={{ __html: i18n.t('mc_not_won_title') }} />
            <Text dangerouslySetInnerHTML={{ __html: i18n.t('mc_not_won_text') }} />

            <FloatBox>
              <Note>
                {i18n.t('mc_not_won_note')}
                {shopLang === 'nl_nl' &&
                  <>
                    &nbsp;
                    <Timer timeInMs={leftTime} />
                  </>
                }
              </Note>

              {setButton(`${i18n.t('mc_not_won_button')}`)}
            </FloatBox>
          </ContentBox>
        </CSSTransition>

        <CSSTransition
          in={isUserWinner === false && isFinalWeek}
          timeout={{ appear: 2000, enter: 2000, exit: 0 }}
          classNames='anim'
          unmountOnExit
        >
          <ContentBox>
            <Title dangerouslySetInnerHTML={{ __html: i18n.t('mc_not_won_final_week_title') }} />
            <Text dangerouslySetInnerHTML={{ __html: i18n.t('mc_not_won_final_week_text') }} />
            <FloatBox>
              {setButton(`${i18n.t('mc_not_won_final_week_button')}`)}
            </FloatBox>
          </ContentBox>
        </CSSTransition>

        <CSSTransition
          in={isUserWinner === true && !isReplayGame}
          timeout={{ appear: 2000, enter: 2000, exit: 0 }}
          classNames='anim'
          unmountOnExit
        >
          <ContentBox>
            <Title dangerouslySetInnerHTML={{ __html: i18n.t('mc_won_title') }} />
            <Text dangerouslySetInnerHTML={{ __html: i18n.t('mc_won_text') }} />
            <FloatBox>
              {setButton(`${i18n.t('mc_won_button')}`)}
            </FloatBox>
          </ContentBox>
        </CSSTransition>

        <CSSTransition
          in={isUserWinner === true && isReplayGame}
          timeout={{ appear: 2000, enter: 2000, exit: 0 }}
          classNames='anim'
          unmountOnExit
        >
          <ContentBox>
            <Title dangerouslySetInnerHTML={{ __html: i18n.t('mc_won_final_week_title') }} />
            <Text dangerouslySetInnerHTML={{ __html: i18n.t('mc_won_final_week_text') }} />
            <FloatBox>
              {setButton(`${i18n.t('mc_won_final_week_button')}`)}
            </FloatBox>
          </ContentBox>
        </CSSTransition>
      </InfoBox>
    </>
  );
};

export default Info;