import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconArrowBlue } from '../../../assets';

const setProfileLinkStyles = (theme: any) => {
  const { color, mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    background: ${color.white};
    border-bottom: 1px solid ${color.cottonGrey};
    padding: 16px;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    &.active {
      ${Text} {
        color: ${color.blue};
      }
    }

    &:hover,
    &.active {
      background: ${color.lightblue};
    }

    ${mediaQueries.md} {
      position: relative;
      padding: 25px 40px 25px 25px;

      &:last-child {
        border-bottom: 1px solid ${color.cottonGrey};
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 25px;
        width: 16px;
        height: 16px;
        background-image: url('${IconArrowBlue}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: transform 0.2s;
      }
    }
  `;
};

export const NavLinkCustom = styled(NavLink)(({ theme }) => {
  return css`
    ${setProfileLinkStyles(theme)}
  `;
});

export const LinkCustom = styled.a(({ theme }) => {
  return css`
    ${setProfileLinkStyles(theme)}
  `;
});

export const ButtonCustom = styled.button(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    ${setProfileLinkStyles(theme)}
    ${mediaQueries.md} {

      &:last-child {
        border-bottom: none;
      }

      &:after {
        content: none;
        display: none;
      }
    }
  `;
});

export const Icon = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 10px;

    svg {
      max-width: 100%;
      height: auto;
      max-height: 24px;
      vertical-align: bottom;
    }

    ${mediaQueries.md} {
      margin-right: 20px;
    }
  `;
});

export const Text = styled.p(({ theme }) => {
  const { color, font, size, mediaQueries } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-size: ${size.px14};
    line-height: 1.1;
    color: ${color.grey};

    ${mediaQueries.md} {
      font-size: ${size.px20};
      font-family: ${font.family.EMPrintSemi};
      color: ${color.darkCharcoal};
      font-weight: ${font.weight.semiBold};
    }
  `;
});