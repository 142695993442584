import { regExpList } from './regExpList';
import { errorsList } from './errorsList';

const passwordScheme = {
  uppercase: {
    regexp: regExpList.password.uppercase,
    errorText: errorsList.password.uppercase
  },
  lowercase: {
    regexp: regExpList.password.lowercase,
    errorText: errorsList.password.lowercase
  },
  number: {
    regexp: regExpList.password.number,
    errorText: errorsList.password.number
  },
  specialCharacter: {
    regexp: regExpList.password.specialCharacter,
    errorText: errorsList.password.specialCharacter
  },
  eightCharacter: {
    regexp: regExpList.password.eightCharacter,
    errorText: errorsList.password.eightCharacter
  }
};

const passwordConfirmScheme = {
  ...passwordScheme,
  match: {
    regexp: null,
    errorText: errorsList.password.match
  }
};

const phoneNumberScheme = {
  selectCountry: {
    regexp: null,
    errorText: errorsList.phoneNumber.selectCountry
  },
  nullStarted: {
    regexp: regExpList.phoneNumber.nullStarted,
    errorText: errorsList.phoneNumber.nullStarted
  },
  onlyNumbers: {
    regexp: regExpList.phoneNumber.onlyNumbers,
    errorText: errorsList.phoneNumber.onlyNumbers
  },
  minMaxLength: {
    regexp: regExpList.phoneNumber.minMaxLength,
    errorText: errorsList.phoneNumber.minMaxLength
  }
};

export { passwordScheme, passwordConfirmScheme, phoneNumberScheme };