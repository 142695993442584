import styled, { css } from 'styled-components';
import { Theme } from '../../../theme';
import { IconInfo, IconCheck, IconLine } from '../../../../prepr/assets';

type ToastWrapperType = {
  positionTop?: string;
  toastType: string;
  width?: string;
};

export const ToastIcon = styled.div(() => {
  return css`
    position: absolute;
    top: 25px;
    left: 17px;
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    border-radius: 50%;
  `;
});

const TOAST_TYPE: { [key: string]: any } = {
  error: css`
    border-left-color: ${Theme.color.brightRed};

    ${ToastIcon} {
      background-image: url('${IconLine}');
      background-color: ${Theme.color.brightRed};
    }
  `,
  warning: css`
    border-left-color: ${Theme.color.orange};
    ${ToastIcon} {
      background-image: url('${IconInfo}');
      background-color: ${Theme.color.orange};
    }
  `,
  info: css`
    border-left-color: ${Theme.color.azure};
    ${ToastIcon} {
      background-image: url('${IconInfo}');
      background-color: ${Theme.color.azure};
    }
  `,
  success: css`
    border-left-color: ${Theme.color.green};
    ${ToastIcon} {
      background-image: url('${IconCheck}');
      background-color: ${Theme.color.green};
    }
  `
};

export const ToastWrapper = styled.div<ToastWrapperType>(
  ({ theme, toastType, positionTop, width }) => {
    const { color, mediaQueries } = theme;

    return css`
      position: fixed;
      right: ${positionTop || '50px'};
      top: ${positionTop || '50px'};
      width: ${ width || '504px'};
      text-align: left;
      z-index: 100;
      background: ${color.white};
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      padding: 25px 50px;
      box-sizing: border-box;
      border-left-style: solid;
      border-width: 4px;

      ${toastType && TOAST_TYPE[toastType]}

      ${mediaQueries.sm} {
        width: ${ width || '250px'};
        max-width: 250px;
      }
    `;
  }
);

export const ToastCloseBtn = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    position: absolute;
    top: 8px;
    right: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -1px;
      height: 16px;
      width: 2px;
      background: ${color.blue};
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  `;
});

export const ToastTitle = styled.h5(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px14};
    line-height: 1.2;
    color: ${color.black};
    margin: 0;
  `;
});

export const ToastText = styled.p(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    font-size: ${size.px14};
    line-height: 1.57;
    color: ${color.grey};
    margin-top: 15px;
  `;
});
