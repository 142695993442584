import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ToastTypes } from './toast.types';

import { AnimatedLayout } from '../../../theme/GlobalStyles';
import {
  ToastWrapper,
  ToastCloseBtn,
  ToastTitle,
  ToastText,
  ToastIcon
} from './Toast.styles';

const Toast: React.FC<ToastTypes> = (props) => {
  const { toastType, isShowed, isAnimated, onClose, title, text, positionTop, width } =
    props;
  const [animationState, setAnimationState] = useState<{
    fadeType: string | null;
  }>({ fadeType: null });

  useEffect(() => {
    isShowed
      ? isAnimated
        ? setAnimationState({ fadeType: 'out' })
        : setTimeout(() => setAnimationState({ fadeType: 'in' }), 0)
      : setAnimationState({ fadeType: 'out' });
  }, [isShowed, isAnimated]);

  return isShowed
    ? ReactDOM.createPortal(
        <AnimatedLayout className={`fade-${animationState.fadeType}`}>
          <ToastWrapper toastType={toastType} positionTop={positionTop} width={width}>
            <ToastCloseBtn onClick={() => onClose()} />
            <ToastIcon />
            <ToastTitle>{title}</ToastTitle>
            {text && <ToastText>{text}</ToastText>}
          </ToastWrapper>
        </AnimatedLayout>,
        document.body
      )
    : null;
};

export default Toast;
