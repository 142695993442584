import styled, {css} from 'styled-components';

export const ModalQuestionWrapper = styled.div(() => {
	return css`
		position: fixed;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.6);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	`;
});

export const ModalCloseBtn = styled.button(() => {
	return css`
		border: none;
		padding: 0;
		background: transparent;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	`;
});

export const ModalBlock = styled.div(({theme}) => {
	const {color, mediaQueries} = theme;
	return css`
		max-width: 460px;
		width: 92%;
		border-radius: 12px;
		background-color: ${color.lightBlue};
		padding: 40px 25px 25px;
		position: relative;
		text-align: left;

		${mediaQueries.sm} {
			padding-left: 20px;
			padding-right: 20px;
		}
	`;
});

export const ModalSubtitle = styled.p(({theme}) => {
	const {color, size, font} = theme;
	return css`
		font-size: ${size.px16};
		line-height: 1.2;
		color: ${color.navy};
		margin: 0 0 15px;
		font-family: ${font.family.EMPrintSemi};
		font-weight: ${font.weight.semiBold};
		max-width: 67%;
		min-width: 255px;
	`;
});

export const ModalTitle = styled.p(({theme}) => {
	const {color, size, font, mediaQueries} = theme;
	return css`
		font-size: ${size.px32};
		line-height: 1.2;
		color: ${color.navy};
		margin: 0 0 20px;
		font-family: ${font.family.EMPrintSemi};
		font-weight: ${font.weight.semiBold};

		${mediaQueries.sm} {
			font-size: ${size.px22};
		}
	`;
});

export const FormWrap = styled.div(() => {
	return css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		
		
		${ModalInputWrap}{
			&:nth-last-of-type(2){
				margin: 0 0 40px;
			}
		}
	`;
});

export const ModalInputWrap = styled.div(({theme}) => {
	const {color} = theme;

	return css`
		position: relative;
		width: 100%;
		margin: 0 0 20px;
		
		input{
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked{
				~ ${ModalLabel}{
					.checkmark {
						:after {
							opacity: 1;
							box-shadow: inset 0 0 0 7px ${color.blue};
						}
					}
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 50%;
			margin-top: -12px;
			left: 20px;
			height: 24px;
			width: 24px;
			border: 1px solid ${color.chineseGray};
			border-radius: 50%;
			background-color: ${color.white};

			&:after{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				box-shadow: inset 0 0 0 0 ${color.blue};
				opacity: 0;
				transition: opacity 0.2s, box-shadow 0.3s ease-in-out;
			}
		}
	`;
});

export const ModalLabel = styled.label(({theme}) => {
	const {color, size, font} = theme;

	return css`
		display: block;
		font-family: ${font.family.sansSerif};
		font-size: ${size.px14};
		font-weight: ${font.weight.regular};
		line-height: 1;
		color: ${color.black};
		cursor: pointer;
		user-select: none;
		border: 1px solid #a4a4a4;
		border-radius: 4px;
		align-self: stretch;
		padding: 24px 20px 24px 56px;
		margin: 0;
		transition: background-color 0.2s;

		&.error {
			border-color: ${color.lightRed};
		}
	`;
});

export const ErrorWrap = styled.p(({theme}) => {
	const {color, size, font} = theme;
	return css`
		position: absolute;
		bottom: -40px;
		left: 0;
		font-size: ${size.px12};
		line-height: 1.2;
		font-family: ${font.family.EMPrintRegular};
		color: ${color.error};
		padding-left: 20px;
		margin: 0;
		
		&:before {
			content: '';
			width: 3px;
			height: 3px;
			background-color: ${color.lightRed};
			position: absolute;
			left: 7px;
			top: 50%;
			transform: translateY(-50%);
		}
	`;
});

export const ButtonSendWrap = styled.div(() => {
	return css`
		margin-top: 20px;
		width: 100%;

		button {
			width: 100%;
			
			&:disabled{
				opacity: 0.8;
			}
		}
	`;
});
