import React, {useState, useRef, useEffect} from 'react';
import {SelectProps} from './customSelect.types';
import {
  SelectWrapper,
  SelectBox,
  OptionsList,
  OptionItem
} from './CustomSelect.styles';
import {IconArrowDownComponent} from '../../../assets';

const CustomSelect: React.FC<SelectProps> = ({options, value, onChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(value);
  const selectRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((option) => option.value === value);

  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const closeDropdown = () => setIsOpen(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <SelectWrapper ref={selectRef}>
      <SelectBox onClick={toggleDropdown} isOpen={isOpen}>
        {selectedOption ? selectedOption.label : 'Select an option'}
        <IconArrowDownComponent/>
      </SelectBox>

      {isOpen && (
        <OptionsList>
          {options.map((option, index) => (
            <OptionItem
              key={option.value}
              highlighted={+highlightedIndex === (index + 1)}
              onClick={() => {
                onChange(option.value);
                setHighlightedIndex(index + 1);
                closeDropdown();
              }}
            >
              {option.label}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </SelectWrapper>
  );
};

export default CustomSelect;