import styled, {css} from 'styled-components';
import {Theme} from '../../../../../redesign/theme';
import {FirTree, FirTreeMob} from './assets';

export const Wrapper = styled.div<{ locale: string }>(({locale}) => {
  return css`
    width: 100%;
    min-height: 100dvh;
    display: flex;
    background: linear-gradient(315deg, #007DDE 20%, #0047BA 100%),
    radial-gradient(67.26% 173.72% at 68.27% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);

    .anim,
    .anim-delay {

      &-enter {
        opacity: 0;
      }

      &-enter-active {
        opacity: 1;
        transition: opacity 2000ms;
      }

      &-exit {
        opacity: 1;
      }

      &-exit-active {
        display: none;
        opacity: 0;
        transition: opacity 0ms;
      }

    }

    .anim-delay {
      &-enter-active {
        transition-delay: 2000ms;
      }
    }

    ${locale !== 'nl_nl' && `
      &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 28vw;
      background: url('${FirTree}') no-repeat left top;
      background-size: cover;
      
      ${Theme.mediaQueries.md} {
        @media (orientation: portrait) {
          height: 70vw;
          background: url('${FirTreeMob}') no-repeat left top;
          background-size: cover;
        }
      }
    }
  `}
  `
});