import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import * as Sentry from '@sentry/react';
import Typewriter from '../Typewriter';
import PrintBar from '../PrintBar';
import i18n from '../../../../../../../config/i18n';
import APIMiniGamesService from '../../../api/api';
import {useNotification} from '../../../../../../../redesign/hooks';
import clearLocaleStorage from '../../../../../../../redesign/utils/clearLocaleStorage';
import {shopLang} from '../../../../../../../config/config';
import {LotteryResultProps} from './device.types';
import {DeviceBox, DeviceContent, PlayButton, ResultIndicator} from './Device.styles';

const Device = ({isUserLoggedIn}: { isUserLoggedIn: boolean }) => {
  const {showNotification, notificationComponent} = useNotification({
      toastType: 'error',
      positionTop: '30px',
      width: 'auto'
    }
  );
  const history = useHistory();
  const location = useLocation();
  const [deviceMessage, setDeviceMessage] = useState('');
  const [startProgressValue, setStartProgressValue] = useState(0);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [isUserWinning, setIsUserWinning] = useState<LotteryResultProps>(null);
  const isUserWinner = useSelector((state: any) => state?.isUserWonCampaign);
  const activeLotteryId = useSelector((state: any) => state?.campaignInfo?.activeLottery?.id);
  const isReplayGame = useSelector((state: any) => state?.isReplayGame);
  const isModalAnswerCorrect = useSelector((state: any) => state?.isModalAnswerCorrectCampaign);

  useEffect(() => {
    if (isReplayGame) {
      setStartProgressValue(100)
    } else {
      setStartProgressValue(0);
    }
  }, [isReplayGame])

  useEffect(() => {
    isUserWinner ? setDeviceMessage(i18n.t('mc_won_message')) :
      isUserWinner === false ? setDeviceMessage(i18n.t('mc_not_won_message')) :
        setDeviceMessage(i18n.t('mc_default_message'))
  }, [isUserWinner]);

  const runLottery = useCallback(async () => {
    try {
      const prize = await APIMiniGamesService.createParticipantWithLottery({campaignGameId: activeLotteryId});
      return await Promise.resolve(prize);
      // return await new Promise((resolve, reject) => setTimeout(() => {
      //   return resolve({prize: {code: '1000'}})
      // }, 1000));
    } catch (error: any) {
      showNotification('Something went wrong, please try again.');
      Sentry.captureMessage(`Error in createParticipantWithLottery: ${error}`, `info`);
      return await Promise.reject(error);
    }
  }, [activeLotteryId, showNotification]);

  const onProgressComplete = useCallback(() => {
    setIsLoadingProgress(false);
    setStartProgressValue(100);
    APIMiniGamesService.setIsUserWon(isUserWinning);
  }, [isUserWinning]);

  const onPlay = useCallback(() => {
    const dateNow = Date.now();
    const accessExpiresAt = localStorage.getItem('accessExpiresAt');
    const authorized = localStorage.getItem('authorized');

    if (authorized && accessExpiresAt && (dateNow >= +accessExpiresAt)) {
      clearLocaleStorage();
      history.push({
        pathname: '/auth/login',
        state: {
          prevPath: location.pathname
        }
      });
    } else {
      setIsLoadingProgress(true);
      setStartProgressValue(0);
      APIMiniGamesService.setIsUserWon(null);
      setIsUserWinning(null);
      setIsDataFetched(false);
    }
  }, [history, location])

  const onShowModal = useCallback(() => {
    shopLang !== 'nl_nl' ? APIMiniGamesService.setIsShowModal(true) : onPlay();
  }, [onPlay]);

  useEffect(() => {
    isLoadingProgress && runLottery()
      .then(result => {
        setIsDataFetched(true);
        if (result?.lotteryPrize?.prize) {
          setIsUserWinning(true);
        } else {
          setIsUserWinning(false);
        }
      })
      .catch(() => {
        setIsLoadingProgress(false);
        setStartProgressValue(0);
        setIsUserWinning(null);
        APIMiniGamesService.setIsUserWon(null);
        APIMiniGamesService.setIsModalAnswerCorrect(false);
      });
  }, [isLoadingProgress, runLottery]);

  useEffect(() => {
    if (isModalAnswerCorrect) {
      onPlay();
      APIMiniGamesService.setIsModalAnswerCorrect(false);
    }
  }, [isModalAnswerCorrect, onPlay]);

  return (
    <DeviceBox>
      <DeviceContent isUserWinner={isUserWinner}>
        <Typewriter text={deviceMessage} repeat={false}/>
        <PrintBar minDuration={3000}
                  startProgressValue={startProgressValue}
                  start={isDataFetched}
                  isUserWin={isReplayGame ? isUserWinner : isUserWinning}
                  onProgressComplete={onProgressComplete}/>
        {isUserLoggedIn && !isReplayGame && !!activeLotteryId &&
          <PlayButton onClick={onShowModal}
                      disabled={isUserWinning !== null || isLoadingProgress}>{i18n.t('mc_play_button')}</PlayButton>
        }
        <ResultIndicator/>
      </DeviceContent>
      {notificationComponent}
    </DeviceBox>
  );
};

export default Device;