import React, {useState, useEffect, useRef} from 'react';
import {TypewriterBox, Text} from './Typewriter.styles';

interface TypewriterProps {
  text: string;
  speed?: number;
  delay?: number;
  repeat?: boolean;
}

const Typewriter = ({text, speed = 100, delay = 1000, repeat = false}: TypewriterProps) => {
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [index, setIndex] = useState(0);
  const typingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setDisplayText('');
    setIndex(0);
    setIsDeleting(false);

    if (typingIntervalRef.current) {
      clearInterval(typingIntervalRef.current);
    }
  }, [text]);

  useEffect(() => {
    if (!text) return;

    const handleTyping = () => {
      if (!isDeleting) {
        if (index < text.length) {
          setDisplayText((prev) => prev + text.replace(/<br\s*\/?>/g, '\n').charAt(index));
          setIndex(index + 1);
        } else {
          repeat && setTimeout(() => setIsDeleting(true), delay);
        }
      } else {
        if (index > 0) {
          setDisplayText('');
          setIndex(0);
        } else {
          setTimeout(() => setIsDeleting(false), delay);
        }
      }
    };

    typingIntervalRef.current = setInterval(handleTyping, speed);

    return () => {
      if (typingIntervalRef.current) {
        clearInterval(typingIntervalRef.current);
      }
    };
  }, [index, isDeleting, text, speed, delay, repeat]);

  return (
    <TypewriterBox>
      <Text>{displayText}</Text>
    </TypewriterBox>
  );
};

export default Typewriter;
