import React from 'react';
import i18n from '../../../../../../config/i18n';
import { useSelector } from 'react-redux';
import {
  CardWrapper,
  CardBalance,
  CardInfo,
  CardQRCode, CardDots, Dots
} from './EssoCard.styles';
import { IconQRCodeComponent } from '../../../../../assets';
import { QRCodeModal } from '../../../modals';
import { useModal } from '../../../../../hooks';

export interface EssoCardProps {
  isProfileMenu?: boolean;
  cardNumber?: string;
}

const EssoCard: React.FC<EssoCardProps> = ({ isProfileMenu = false, cardNumber }) => {
  const accountBalance = useSelector((state: { currentUser: any }) => state?.currentUser?.accountBalance);
  const { isShowed, toggle, close, isAnimated } = useModal();

  const onShowQRCode = () => {
    isProfileMenu && toggle();
  };

  return (
    <>
      <CardWrapper onClick={onShowQRCode} isProfileMenu={isProfileMenu}>
        {
          isProfileMenu ?
            <CardBalance>
              <span>{accountBalance}</span><br />
              <span>{i18n.t('06-Banner_balance')}</span>
            </CardBalance> :
            <CardDots><Dots /></CardDots>
        }
        <CardInfo isProfileMenu={isProfileMenu}>
          <span>{i18n.t('esso-card-label')}</span>
          <span>{cardNumber || ('*').repeat(19)}</span>
        </CardInfo>
        {isProfileMenu && <CardQRCode><IconQRCodeComponent /></CardQRCode>}
      </CardWrapper>
      {isShowed &&
        <QRCodeModal
          isShowed={isShowed}
          className={'QRCodeModalBox'}
          onClose={() => close()}
          isAnimated={isAnimated}
          maxWidth='320px'
          isModalCloseBtn={true} />}
    </>
  );
};

export default EssoCard;