import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../../../../../redesign/hooks';
import { Button } from '../../../../../../redesign/components/shared';
import i18n from '../../../../../../config/i18n';
import { shopLang } from '../../../../../../config/config';
import {checkLoggedIn} from '../../../../../../components/utils/_app-helpers';
import {
  Wrapper,
  Container,
  ImageWrapper,
  ContentWrapper,
  PreTitle,
  Title,
  Text,
  Remark,
  ButtonWrapper
} from './EYCInfo.styles';
import { InfoNL1, InfoFR1 } from '../../assets/phase1';
import { InfoNL2, InfoFR2 } from '../../assets/phase2';
import {PAGE_PATH} from "../../../../../../redesign/utils";

const EYCInfo = () => {
  const isLoggedIn = checkLoggedIn();
  const location = useLocation();
  const windowSize = useWindowSize();
  const locale = shopLang.slice(0, 2).toLowerCase();
  const phase = useSelector((state: any) => state?.infoEYC?.name);
  const isFinished = useSelector((state: any) => state?.infoEYC?.isFinished);

  const imgURL = (phase === `ey-2024-${locale}-blue`) ? (shopLang === 'be_fr' ? InfoFR1 : InfoNL1) :
    (phase === `ey-2024-${locale}-gold` && shopLang === 'be_fr') ? InfoFR2 : InfoNL2;

  const Image =
    <ImageWrapper>
      <img src={imgURL} alt='info' />
    </ImageWrapper>;

  return (
    <Wrapper>
      <Container>
        {isFinished ?
          <>
            {windowSize >= 768 && Image}
            <ContentWrapper>
              <Title>{i18n.t('eyc_2024_info_completed_title')}</Title>
              <Text>{i18n.t('eyc_2024_info_completed_text')}</Text>
              {windowSize < 768 && Image}

              <ButtonWrapper>
                <Button buttontype={'primary'}
                        text={i18n.t('eyc_2024_rewards_btn')}
                        url={'/rewards/all'}
                />
              </ButtonWrapper>
            </ContentWrapper>
          </> :
          phase === `ey-2024-${locale}-blue` ?
          <>
            {windowSize >= 768 && Image}
            <ContentWrapper>
              <PreTitle>{i18n.t('eyc_2024_1_info_preTitle')}</PreTitle>
              <Title>{i18n.t('eyc_2024_1_info_title')}</Title>
              <Text>{i18n.t('eyc_2024_1_info_text')}</Text>
              {windowSize < 768 && Image}
              <Remark>{i18n.t('eyc_2024_1_info_remark')}</Remark>

              <ButtonWrapper>
                <Button buttontype={'primary'}
                        text={isLoggedIn ? i18n.t('eyc_2024_play_btn2') : i18n.t('eyc_2024_loggedOut_btn')}
                        url={isLoggedIn ? `/${PAGE_PATH.EYC_2024_GAME}` : { pathname: '/auth/login', state: { prevPath: location.pathname }}}
                />
              </ButtonWrapper>
            </ContentWrapper>
          </> :
          <>
            {windowSize >= 768 && Image}
            <ContentWrapper>
              <Title>{i18n.t('eyc_2024_2_info_title')}</Title>
              <Text>{i18n.t('eyc_2024_2_info_text')}</Text>
              {windowSize < 768 && Image}

              <ButtonWrapper>
                <Button buttontype={'primary'}
                        text={i18n.t('eyc_2024_rewards_btn')}
                        url={'/rewards/all'}
                />
              </ButtonWrapper>
            </ContentWrapper>
          </>
        }
      </Container>
    </Wrapper>
  );
};

export default EYCInfo;