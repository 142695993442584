import React from 'react';
import i18n from '../../../../../../config/i18n';
import {
  CardWrapper,
  CardInfo, CardDots, Dots
} from './PaymentCard.styles';

export interface PaymentCardProps {
  isProfileMenu?: boolean;
  cardNumber?: string;
}

const PaymentCard: React.FC<PaymentCardProps> = ({cardNumber}) => {
  return (
    <>
      <CardWrapper>
        <CardDots><Dots /></CardDots>
        <CardInfo>
          <span>{i18n.t('fuel-card-label')}</span>
          <span>{cardNumber || ('*').repeat(19)}</span>
        </CardInfo>
      </CardWrapper>
    </>
  );
};

export default PaymentCard;