import React, { IframeHTMLAttributes } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { SwitchContainer, SwitchThumb, SwitchInput, SwitchTrack, SwitchLabel } from './Switch.styles';

interface SwitchProps extends IframeHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn;
  disabled?: boolean;
  name: string;
  label?: string;
  checked?: boolean;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch = ({
                  register,
                  disabled = false,
                  name,
                  label,
                  checked = false,
                  handleOnChange
                }: SwitchProps) => {
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    register?.onChange(e);
    handleOnChange && handleOnChange(e);
  };

  return (
    <SwitchContainer>
      {label && <SwitchLabel>{label}</SwitchLabel>}
      <SwitchTrack>
        <SwitchInput type='checkbox'
                     {...register}
                     name={name}
                     defaultChecked={checked}
                     disabled={disabled}
                     onChange={handleSwitchChange}
        />
        <SwitchThumb />
      </SwitchTrack>
    </SwitchContainer>
  );
};

export default Switch;
