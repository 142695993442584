import styled, {css} from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';
import {LotteryResultProps} from "../Device/device.types";
import {LoseBE, LoseFR, LoseNL, WinBE, WinFR, WinNL} from '../../assets';

export const PrintBarWrapper = styled.div`
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  height: 285px;
  overflow: hidden;

  ${Theme.mediaQueries.xl} {
    top: 10.5vw;
    height: 20vw;
  }

  ${Theme.mediaQueries.md} {
    top: 150px;
    height: 285px;
  }

  ${Theme.mediaQueries.xxs} {
    top: 26vw;
    height: 50vw;
  }
`;

export const PrintBarBox = styled.div`
  background: linear-gradient(180deg, #333333 0%, #4C4C4C 61.5%, #333333 96.5%);
  z-index: 2;
  border: 2px solid ${Theme.color.black};
  border-radius: 5px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
`;

type PrintResultProps = {
  isUserWin?: LotteryResultProps,
  locale: string
}

export const PrintResult = styled.div<PrintResultProps>(({isUserWin, locale }) => {
  const image = isUserWin ?
    (locale === 'be_fr' ? WinFR : locale === 'be_nl' ? WinBE : WinNL) :
    (locale === 'be_fr' ? LoseFR : locale === 'be_nl' ? LoseBE : LoseNL);

  return css `
    position: absolute;
    left: 3%;
    width: 96%;
    height: 285px;
    background: url('${image}') top center no-repeat;
    background-size: cover;
    z-index: 3;

    ${Theme.mediaQueries.xl} {
      height: 20vw;
    }

    ${Theme.mediaQueries.md} {
      height: 285px;
    }

    ${Theme.mediaQueries.xxs} {
      height: 50vw;
    }
  `
})