import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Info from './parts/Info';
import Lottery from './parts/Lottery';
import ShareButtons from './parts/ShareButtons';
import APIMiniGamesService from '../api/api';
import { useLoader, useNotification } from '../../../../../redesign/hooks';
import { checkLoggedIn, isWebView } from '../../../../../components/utils/_app-helpers';
import i18n from '../../../../../config/i18n';
import { shopLang } from '../../../../../config/config';
import { Container, Terms, TermsLink } from '../MiniGames.styles';
import { Wrapper } from './FortuneBar.styles';

const FortuneBar = () => {
  const isLoggedIn = checkLoggedIn();
  const locale = shopLang.slice(0, 2).toLowerCase();
  const { showNotification, notificationComponent } = useNotification({
    toastType: 'error',
    positionTop: '30px',
    width: 'auto'
  });
  const accountId = localStorage.getItem('accountId');
  const webView = isWebView();
  const currentUser = useSelector((state: any) => state.currentUser);
  const isCurrentUserDataLoaded = useSelector((state: any) => state?.isCurrentUserDataLoaded);
  const cardNumber = useSelector((state: any) => state?.currentUser?.masterCardNumber);

  const campaignId = useSelector((state: any) => state?.campaignInfo?.id);
  const isStartedCampaign = useSelector((state: any) => state?.campaignInfo?.isStarted);
  const isFinishedCampaign = useSelector((state: any) => state?.campaignInfo?.isFinished);

  const activeLotteryId = useSelector((state: any) => state?.campaignInfo?.activeLottery?.id);
  const activeLotterySlug = useSelector((state: any) => state?.campaignInfo?.activeLottery?.slug);

  const userData = useSelector((state: any) => state?.userData);

  const [isFinalWeek, setIsFinalWeek] = useState(false);
  const [syncUserState, setSyncUserState] = useState(false);

  const hasReloaded = localStorage.getItem('hasReloaded');

  const termsDownload =
    shopLang === 'be_nl' ?
      'https://storage.googleapis.com/media-esso-mini-campaigns-prod/Terms/Voorwaarden%20Esso%20Extras%20Points%20Fietsmaand%20-%20March%202025-%20BEVL-VO.pdf' :
      shopLang === 'be_fr' ?
        'https://storage.googleapis.com/media-esso-mini-campaigns-prod/Terms/Voorwaarden%20Esso%20Extras%20Mois%20du%20velo-Mars%202025-FR-VO.pdf' :
        'https://storage.googleapis.com/media-esso-mini-campaigns-prod/Terms/Voorwaarden%20Esso%20Extras%20Festivalmaand%20-March%202025-%20NL-VO.pdf';

  const isUserLoggedIn = isLoggedIn && !!currentUser && !!currentUser.accountID;
  const loader = useLoader(!hasReloaded || !isStartedCampaign || isFinishedCampaign || (isLoggedIn ? (!isCurrentUserDataLoaded || !syncUserState) : false));

  const setPrevLotteryState = (val: boolean) => {
    APIMiniGamesService.setIsReplayGame(true);
    APIMiniGamesService.setIsUserWon(val);
    setSyncUserState(true);
  };

  useEffect(() => {
    APIMiniGamesService.getCampaignBySlug(`mini-campaigns-${locale}`)
      .catch((error) => {
        showNotification('Something went wrong, please try again.');
      });
  }, [showNotification, locale]);

  const dataSignIn = useMemo(() => {
    return {
      email: `${accountId}@example.com`,
      firstName: '',
      lastName: '',
      campaignId: campaignId,
      data: {
        accountId: accountId || '',
        cardNumber: cardNumber || ''
      }
    };
  }, [campaignId, accountId, cardNumber]);

  useEffect(() => {
    if (isLoggedIn && currentUser && !!currentUser.accountID && campaignId && isStartedCampaign && !isFinishedCampaign) {
      APIMiniGamesService.signIn({ ...dataSignIn })
        .then(() => APIMiniGamesService.updateUser())
        .catch((err) => {
          showNotification('Something went wrong, please try again.');
        });
    }
  }, [isLoggedIn, currentUser, currentUser.accountID, campaignId, dataSignIn, isStartedCampaign, isFinishedCampaign, showNotification]);


  useEffect(() => {
    if (activeLotterySlug?.replaceAll(' ', '')?.includes('week-4')) {
      setIsFinalWeek(true);
    }

    if (userData) {
      const activeUserLottery = userData?.campaignGameStats?.find((lottery: any) => lottery.campaignGameId === activeLotteryId);
      const userHasAlreadyWon = activeUserLottery?.winners?.length > 0;

      if (!activeUserLottery) {
        const userLastWeekLottery = userData?.campaignGameStats?.find((lottery: any) => lottery?.campaignGameSlug?.replaceAll(' ', '')?.includes('week-4'));
        const userHasAlreadyWonLastWeek = userLastWeekLottery?.winners?.length > 0;

        setIsFinalWeek(true);

        if (userHasAlreadyWonLastWeek) {
          setPrevLotteryState(true);
        } else {
          setPrevLotteryState(false);
        }
      } else {
        if (userHasAlreadyWon && !activeUserLottery?.canRunLottery) {
          setPrevLotteryState(true);
        } else if (!userHasAlreadyWon && !activeUserLottery?.canRunLottery) {
          setPrevLotteryState(false);
        } else {
          APIMiniGamesService.setIsReplayGame(false);
          APIMiniGamesService.setIsUserWon(null);
          setSyncUserState(true);
        }
      }
    }
  }, [activeLotterySlug, userData, activeLotteryId]);

  useEffect(() => {
    if (!hasReloaded) {
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, [hasReloaded]);

  useEffect(() => {
    return () => {
      APIMiniGamesService.setUserData(null);
    };
  }, []);

  return (
    <>
      {loader ||
        <Wrapper locale={shopLang}>
          <Container maxWidth={'1450px'} paddingSm={!isLoggedIn && shopLang === 'nl_nl'}>
            {
              webView ?
                <Terms href={termsDownload} download>{i18n.t('mc_default_terms')}</Terms> :
                <TermsLink to={`/${i18n.t('mc_terms_link')}`}
                           rel='noopener noreferrer'
                           target='_blank'>{i18n.t('mc_default_terms')}</TermsLink>
            }
            <Info isUserLoggedIn={isUserLoggedIn}
                  isFinalWeek={isFinalWeek} />
            <Lottery isUserLoggedIn={isUserLoggedIn} />
            <ShareButtons />
          </Container>
        </Wrapper>}
      {notificationComponent}
    </>
  );
};

export default FortuneBar;