import { prepr } from '../services/prepr';
import { getLocalePrepr } from '../../components/utils/_app-helpers';

async function getProfileMenuLinks() {
  let locale = getLocalePrepr();

  try {
    const response = await prepr
      .graphqlQuery(`{
        ProfileMenuLinks(limit: null, locale: "${locale}") { 
          items {
            cta_label
            cta_icon
            cta_active_icon
            cta_url
            order
            is_cta_url_external
            internal_link { 
              __typename
              ... on Page {
                _id
                _slug
              }
            }
          }
        }
      }`).fetch();

    return response.data.ProfileMenuLinks.items;
  } catch (error) {
    console.error('Error fetching profile menu links: ', error);
    return [];
  }
}

export default getProfileMenuLinks;