import styled, {css} from 'styled-components';

export const ModalQuestionWrapper = styled.div(() => {
	return css`
		position: fixed;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.6);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	`;
});

export const ModalCloseBtn = styled.button(() => {
	return css`
		border: none;
		padding: 0;
		background: transparent;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	`;
});

export const ModalBlock = styled.div(({theme}) => {
	const {color, mediaQueries} = theme;
	return css`
		max-width: 460px;
		width: 92%;
		border-radius: 12px;
		background-color: ${color.lightBlue};
		padding: 40px 25px 25px;
		position: relative;
		text-align: left;

		${mediaQueries.sm} {
			padding-left: 20px;
			padding-right: 20px;
		}
	`;
});

export const ModalSubtitle = styled.p(({theme}) => {
	const {color, size, font} = theme;
	return css`
		font-size: ${size.px16};
		line-height: 1.2;
		color: ${color.navy};
		margin: 0 0 15px;
		font-family: ${font.family.EMPrintSemi};
		font-weight: ${font.weight.semiBold};
		max-width: 67%;
		min-width: 255px;
	`;
});

export const ModalTitle = styled.p(({theme}) => {
	const {color, size, font, mediaQueries} = theme;
	return css`
		font-size: ${size.px32};
		line-height: 1.2;
		color: ${color.navy};
		margin: 0 0 20px;
		font-family: ${font.family.EMPrintSemi};
		font-weight: ${font.weight.semiBold};

		${mediaQueries.sm} {
			font-size: ${size.px22};
		}
	`;
});

export const ButtonSendWrap = styled.div(({theme}) => {
	const {color} = theme;
	return css`
		width: 100%;

		button {
			width: 100%;
			
			&:first-child{
				margin-bottom: 10px;
			}

			&:last-child{
				border: 1px solid ${color.navy};
			}
			
			&:disabled{
				opacity: 0.8;
			}
		}
	`;
});
