import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  IconArrowAdmiralRight,
  IconArrowNavyRight,
  IconArrowRight
} from '../../../../prepr/assets';

const setStyles = (theme: any) => {
  const { font, size, color } = theme;

  return css`
    position: relative;
    display: inline-block;

    span {
      position: relative;
      display: inline-block;
      padding-right: 25px;
      font-family: ${font.family.EMPrintSemi};
      font-size: ${size.px14};
      line-height: 1;
      color: ${color.blue};
      text-decoration: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: -1px;
        background: url(${IconArrowRight}) center no-repeat;
        background-size: contain;
        width: 14px;
        height: 16px;
      }
    }
    
    &:active,
    &:hover {
      span{
        text-decoration: underline;
      }
    }

    &:active {
      span{
        color: ${color.navy};
        transition: all 0.2s ease-in-out;

        &:after {
          background-image: url(${IconArrowAdmiralRight});
        }
      }
    }

    &:hover,
    &:focus {
      span{
        color: ${color.navy};
        transition: all 0.3s ease-in-out;

        &:after {
          background-image: url(${IconArrowNavyRight});
        }
      }
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid ${color.azure};
    }
  `;
};

export const CustomLinkWrapper = styled(Link)(({ theme }) => {
  return css`
    ${setStyles(theme)}
  `;
});

export const CustomNativeLinkWrapper = styled.a(({ theme }) => {
  return css`
    ${setStyles(theme)}
  `;
});