import styled, {css} from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';

export const ShareWrapper = styled.div(() => {
  return css`
    position: absolute;
    right: 25px;
    bottom: 40px;
    z-index: 10;

    ${Theme.mediaQueries.md} {
      bottom: 25px;
    }
    
    ${Theme.mediaQueries.sm} {
      bottom: 15px;
      right: 15px;
    }
  `;
});

export const ToggleShare = styled.button(({isOpen}: { isOpen: boolean }) => {
  return css`
    padding: 10px;
    min-height: 48px;
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    box-shadow: 0 2px 6px 0 #00000040;
    background: ${isOpen ? Theme.color.navy : Theme.color.lightblue};
    border-radius: 25px;
    transition: all ease-in-out 0.2s;

    span {
      margin-left: 10px;
      font-family: ${Theme.font.family.EMPrintRegular};
      font-weight: ${Theme.font.weight.regular};
      color: ${isOpen ? Theme.color.lightblue : Theme.color.navy};
      font-size: ${Theme.size.px16};
      transition: all ease-in-out 0.2s;
    }

    ${Theme.mediaQueries.md} {
      padding: 0;
      width: 48px;
      height: 48px;
      min-height: auto;
      min-width: auto;
      border-radius: 50%;

      span {
        display: none;
      }
    }
  `;
});

export const ShareOptions = styled.div(({isOpen}: { isOpen: boolean }) => {
  return css`
    visibility: ${isOpen ? 'visible' : 'hidden'};
    opacity: ${isOpen ? 1 : 0};
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -24px;
    transition: all ease-in-out 0.2s;
    transition-delay: ${isOpen ? '0s' : '0.2s'};

    ${ShareBtn} {
      ${isOpen && `
      &:last-child{
        opacity: 1;
        transition: opacity ease-in-out 0.1s;
        transition-delay: 0s;
      }

      &:first-child{
        opacity: 1;
        transition: opacity ease-in-out 0.1s;
        transition-delay: 0.1s;
      }
    `}
  `;
});

export const ShareBtn = styled.button(() => {
  return css`
    position: relative;
    opacity: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Theme.color.lightblue};
    box-shadow: 0 2px 6px 0 #00000040;
    border-radius: 50%;
    margin: 10px 0;
    
    &:first-child{
      transition-delay: 0s;
    }
    
    &:last-child{
      transition-delay: 0.1s;
    }
  `;
});

export const InfoText = styled.span(() => {
  return css`
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    top: 9px;
    right: 120%;
    min-width: 80px;
    font-family: ${Theme.font.family.EMPrintRegular};
    font-weight: ${Theme.font.weight.regular};
    background-color: ${Theme.color.lightblue};
    color: ${Theme.color.navy};
    font-size: ${Theme.size.px10};
    line-height: 1;
    transition: all ease-in-out 0.2s;

    ${Theme.mediaQueries.md} {
    }
  `;
});
