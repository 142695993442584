import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const MainNavigationWrapper = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    background: ${color.white};
  `;
});

export const MainNavigationBox = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;

    ${mediaQueries.md} {
     height: 56px;
    }
  `;
});

export const ContentLeft = styled.div(() => {
  return css`
    display: flex;
    align-items: center;
    height: 100%;
  `;
});

export const LogoBox = styled(Link)(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    display: block;
    width: 164px;

    ${mediaQueries.md} {
      width: 115px;
    }
    
    img{
      width: 100%;
      height: auto;
    }
  `;
});

export const NavigationBox = styled.nav(() => {
  // const { mediaQueries } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  `;
});

export const NavigationBar = styled.div(({theme}) => {
  const { mediaQueries, color } = theme;

  return css`
    height: 100%;
    
    ${mediaQueries.md} {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: auto;
      background: ${color.white};
      z-index: 100;
      flex-direction: column;

      transform-origin: left top;
      transform: scale(0);
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

      &.active{
        transform-origin: left top;
        transform: scale(1);
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
      }
    }
  `;
});

export const NavigationIcon = styled.span(() => {
  // const { mediaQueries } = theme;

  return css`
    display: block;
    width: 25px;
    height: 25px;
    border: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  `;
});
