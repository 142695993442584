import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalTypes } from './modal.types';
import { useWindowSize } from '../../../../hooks';

import { AnimatedLayout } from '../../../../theme/GlobalStyles';
import {
  ModalOverlay,
  ModalWrapper,
  ModalBox,
  ModalCloseBtn,
  ModalTitle
} from './Modal.styles';

const Modal: React.FC<ModalTypes> = (props) => {
  const {
    children,
    isShowed,
    isAnimated,
    onClose,
    maxWidth = '588px',
    isModalCloseBtn = true,
    title,
    className,
    overlayStyle
  } = props;
  const [animationState, setAnimationState] = useState<{
    fadeType: string | null;
  }>({ fadeType: null });
  const windowSize = useWindowSize();

  useEffect(() => {
    isShowed
      ? isAnimated
        ? setAnimationState({ fadeType: 'out' })
        : setTimeout(() => setAnimationState({ fadeType: 'in' }), 0)
      : setAnimationState({ fadeType: 'out' });
  }, [isShowed, isAnimated]);

  useEffect(() => {
    if (isShowed) {
      const defaultStyleOverflow = document.body.style.overflow;
      document.body.style.marginRight =
        window.innerWidth - document.body.clientWidth + 'px';
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = defaultStyleOverflow;
        document.body.style.removeProperty('margin-right');
      };
    }
  }, [isShowed, windowSize]);

  return isShowed
    ? ReactDOM.createPortal(
        <AnimatedLayout className={`fade-${animationState.fadeType}`} id={'modalPortal'}>
          <ModalWrapper>
            <ModalOverlay onClick={() => onClose()} style={overlayStyle}/>
            <ModalBox maxWidth={maxWidth} className={className}>
              {isModalCloseBtn && <ModalCloseBtn onClick={() => onClose()} />}
              {title && <ModalTitle>{title}</ModalTitle>}
              {children}
            </ModalBox>
          </ModalWrapper>
        </AnimatedLayout>,
        document.body
      )
    : null;
};

export default Modal;
