const setRecaptcha = () => {
  if ((typeof process.env.REACT_APP_RECAPTCHA_KEY === 'string') &&
    !document.documentElement.innerHTML.includes(process.env.REACT_APP_RECAPTCHA_KEY)) {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
    script.async = true;
    document.body.appendChild(script);
  }
};

export default setRecaptcha;