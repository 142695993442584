import React, {useState} from 'react';
import {Button} from '../../../../../../../redesign/components/shared';
import i18n from '../../../../../../../config/i18n';
import APIMiniGamesService from '../../../api/api';
import {
  ModalQuestionWrapper,
  ModalBlock,
  ButtonSendWrap,
  FormWrap,
  ErrorWrap,
  ModalSubtitle,
  ModalTitle,
  ModalLabel,
  ModalInputWrap,
  ModalCloseBtn
} from './ModalBE.style';
import {ModalCloseComponent} from '../../assets';

function ModalBE() {
  const [error, setError] = useState<boolean | null>(null);
  const [isCheck, setIsCheck] = useState(true);
  const [localAnswerState, setLocalAnswerState] = useState(false);

  const onRadioChanged = (event: any) => {
    setIsCheck(false);

    if (event.target.value === 'true') {
      setError(false);
      setLocalAnswerState(true);
    } else {
      setLocalAnswerState(false);
    }
  };

  const hideModal = () => {
    setError(false);
    APIMiniGamesService.setIsShowModal(false);
  };

  const submitAnswer = () => {
    if (localAnswerState) {
      APIMiniGamesService.setIsModalAnswerCorrect(true);
      setError(false);
      hideModal();
    } else {
      APIMiniGamesService.setIsModalAnswerCorrect(false);
      setError(true);
    }
  };

  return (
    <ModalQuestionWrapper>
      <ModalBlock>
        <ModalCloseBtn onClick={() => hideModal()}>
          <ModalCloseComponent/>
        </ModalCloseBtn>
        <ModalSubtitle>{i18n.t('mc_modal_intro')}</ModalSubtitle>
        <ModalTitle dangerouslySetInnerHTML={{__html: i18n.t('mc_modal_question')}}/>
        <FormWrap>
          <ModalInputWrap>
            <input type='radio' name='answer' id='answer1' value={'true'} onChange={onRadioChanged}/>
            <ModalLabel htmlFor='answer1'>
              {i18n.t('mc_modal_1_answer')}
              <span className='checkmark'/>
            </ModalLabel>
          </ModalInputWrap>

          <ModalInputWrap>
            <input type='radio' name='answer' id='answer2' value={'false'} onChange={onRadioChanged}/>
            <ModalLabel htmlFor='answer2' className={error ? 'error' : ""}>
              {i18n.t('mc_modal_2_answer')}
              <span className='checkmark'/>
            </ModalLabel>
            {error && (
              <ErrorWrap>
                {i18n.t('eBike_validation_question_error')}
              </ErrorWrap>
            )}
          </ModalInputWrap>

          <ButtonSendWrap>
            <Button
              disabled={isCheck}
              text={i18n.t('eyc_2024_modal_button')}
              buttontype={'primary'}
              onClick={submitAnswer}
            />
          </ButtonSendWrap>
        </FormWrap>
      </ModalBlock>
    </ModalQuestionWrapper>
  );
}

export default ModalBE;