import styled, {css} from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';
import {FieldContainer} from '../../../../../../../redesign/components/shared/Form/InputField/InputField.styles';

export const FormWrapper = styled.div(() => {
  return css`
    width: 100%;
  `;
});

export const FormTitle = styled.h2(() => {
  return css`
    padding-bottom: 15px;
    font-family: ${Theme.font.family.EMPrintRegular};
    font-size: ${Theme.size.px32};
    color: ${Theme.color.navy};
    line-height: 1.2;

    ${Theme.mediaQueries.sm} {
      font-size: ${Theme.size.px24};
    }
  `;
});

export const InputWrap = styled.div(() => {
  return css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    input::placeholder {
      font-family: ${Theme.font.family.sansSerif};
      font-size: ${Theme.size.px16};
      font-weight: ${Theme.font.weight.regular};
      color: ${Theme.color.grey};
      line-height: 1;
    }

    ${FieldContainer} {
      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }

    ${Theme.mediaQueries.sm} {
      ${FieldContainer} {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
  `;
});

export const FormInfo = styled.div(() => {
  return css`
    background: ${Theme.color.lightblue};
    border-radius: 4px;
    padding: 1.2rem 0.75rem;
    font-family: ${Theme.font.family.sansSerif};
    font-size: ${Theme.size.px14};
    font-weight: ${Theme.font.weight.regular};
    color: ${Theme.color.navy};
    line-height: 1.4;
    margin-bottom: 1rem;
  `;
});

export const FormContact = styled.div(() => {
  return css`
    margin-bottom: 2rem;

    span {
      font-family: ${Theme.font.family.sansSerif};
      font-size: ${Theme.size.px12};
      font-weight: ${Theme.font.weight.regular};
      color: ${Theme.color.grey};
      line-height: 1.2;
    }

    a {
      font-family: ${Theme.font.family.EMPrintRegular};
      font-size: ${Theme.size.px14};
      font-weight: ${Theme.font.weight.semiBold};
      color: ${Theme.color.navy};
      line-height: 1.2;
      text-decoration: underline;
      cursor: pointer;
    }
  `;
});

export const ButtonBox = styled.div(() => {
  return css`
    button {
      margin: 0;
      width: 100%;

      &:disabled {
        background-color: ${Theme.color.olympic};
      }
    }
  `;
})