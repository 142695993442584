import { createSelector } from 'reselect';

export const getAccessToken = createSelector(
  [(state) => state.authData],
  (authData) => authData.accessToken
);

export const getRefreshToken = createSelector(
  [(state) => state.authData],
  (authData) => authData.refreshToken
)