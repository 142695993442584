import styled, {css} from 'styled-components';
import {Theme} from '../../../theme';

const styles = css`
  font-family: ${Theme.font.family.sansSerif};
  font-size: ${Theme.size.px16};
  color: ${Theme.color.black};
  background-color: ${Theme.color.white};
  cursor: pointer;
  min-height: 48px;
  padding: 12px;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 280px;

  ${Theme.mediaQueries.md} {
    width: 100%;
  }
`;

export const SelectBox = styled.div<{ isOpen: boolean }>`
  ${styles};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  border: 1px solid ${Theme.color.chineseGray};
  border-radius: 4px 4px ${({isOpen}) => isOpen ? 0 : 4}px ${({isOpen}) => isOpen ? 0 : 4}px;

  svg {
    height: 10px;
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -5px;
    transition: all 0.3s ease-in-out;
    transform: ${({isOpen}) => isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'};

    path {
      fill: ${Theme.color.grey};
    }
  }
`;

export const OptionsList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid ${Theme.color.chineseGray};
  border-top: none;
  border-bottom: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

export const OptionItem = styled.div<{ highlighted: boolean }>`
  ${styles};

  border-bottom: 1px solid ${Theme.color.chineseGray};
  background-color: ${({highlighted}) => (highlighted && Theme.color.lightblue)};
  color: ${({highlighted}) => (highlighted && Theme.color.blue)};
  transition: background 0.2s;

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    background-color: ${Theme.color.lightblue};
  }
`;