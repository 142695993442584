import styled from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';

export const TypewriterBox = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  min-height: 60px;
  background-color: #222121;
  border: 4px solid ${Theme.color.black};
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Theme.mediaQueries.xl} {
    top: 4vw;
    min-height: 4.2vw;
  }

  ${Theme.mediaQueries.md} {
    top: 60px;
    min-height: 60px;
  }

  ${Theme.mediaQueries.xxs} {
    top: 8vw;
    min-height: 13.5vw;
  }
`;

export const Text = styled.p`
  font-family: 'Tiny5', serif;
  font-weight: ${Theme.font.weight.regular};
  font-style: normal;
  font-size: ${Theme.size.px16};
  line-height: 1.2;
  text-transform: uppercase;
  white-space: pre-line;
  transition: all ease 0.3s;

  ${Theme.mediaQueries.xl} {
    font-size: 1.14vw;
  }

  ${Theme.mediaQueries.md} {
    font-size: ${Theme.size.px16};
  }

  ${Theme.mediaQueries.xxs} {
    font-size: 3.2vw;
  }
`;