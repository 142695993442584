import React from 'react';
import {HashRouter as Router, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
// import * as actions from '../../redux/actions';
import i18n from 'i18next';
import {Trans, translate} from 'react-i18next';
// import {getAllCards} from '../utils/_profile-helpers';
// import {getCurrentUser} from '../utils/_home-helpers';
import ShowMemberCard from '../common/modals/ShowMemberCard';
import SVG from 'react-inlinesvg';
// import {handleUnauthorize} from '../utils/_app-helpers';
import {onCloseModal} from '../common/modals/_modals-helper';
import { clearLocaleStorage } from "../../redesign/utils";
import { useWindowSize } from '../../redesign/hooks';

import logOut from '../../img/profile/log-out.svg';
import '../../components/profile/Profile.scss';

const translateLogOut = () => {
	let logOutString = document.querySelector('.log-out-button tspan');
	let translationUpper = i18n.t('06-Submenu_link_6').toUpperCase();
	if (logOutString) {
		logOutString.textContent = translationUpper;
	}
};

class ProfileHeader extends React.Component {
	constructor() {
		super();

		this.state = {
			showMemberCard: false,
		};

		this.showMemberCard = this.showMemberCard.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	showMemberCard() {
		this.setState({
			showMemberCard: true,
		});
	}

	handleCloseModal() {
		this.setState({
			showMemberCard: false,
		});
		onCloseModal();
	}

	componentWillMount() {
		// let that = this;
		// let authorized = localStorage.getItem('authorized');
		// let token = JSON.parse(localStorage.getItem('authToken'));
		// if (authorized) {
		// 	getCurrentUser(token.accessToken)
		// 		.then((response) => {
		// 			debugger;
		// 			const accountId = response.data.payload.accountID;
		// 			getAllCards(accountId, token.accessToken).then((response) => {
		// 				that.props.dispatch(actions.addCardsData(response.data.payload));
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			handleUnauthorize(error, token);
		// 		});
		// }
		const main = document.querySelector('.main-nav');
		main && main.classList.add('active-profile');
	}

	componentDidMount() {
		document.querySelector('.main-nav') && document.querySelector('.main-nav').classList.remove('active-profile');
		translateLogOut();

		let authorized = localStorage.getItem('authorized');
		if (!authorized) {
			this.props.history.push({
				pathname: '/auth/login',
				state: {
					prevPath: this.props.location.pathname
				}
			})
		}
	}

	render() {
		return (
			!this.props.webView && (
				<header className="profile-header">
					<ProfileHeaderInfo
						currentUser={this.props.currentUser}
						showMemberCard={this.showMemberCard}
						amountBig={this.props.amountBig}
					/>
					<ProfileMenu />
					<ShowMemberCard
						currentUser={this.props.currentUser}
						handleCloseModal={this.handleCloseModal}
						isOpen={this.state.showMemberCard}
					/>
				</header>
			)
		);
	}
}

export const logOutHandler = (isModal, noreload) => {
	clearLocaleStorage();

	!noreload && window.location.reload();
};
const ProfileMenu = (props) => {
	const windowSize = useWindowSize();

	return (
		<section className="profile-top-menu-wrapper">
			<section className="profile-top-menu">
				<Router>
					<nav>
						<NavLink to="/profile/overview" activeClassName="active">
							<Trans i18nKey="06-Submenu_link_1"></Trans>
						</NavLink>
						<NavLink to="/profile/transactions" activeClassName="active">
							<Trans i18nKey="06-Submenu_link_2"></Trans>
						</NavLink>
						<NavLink to="/profile/rewards" activeClassName="active">
							<Trans i18nKey="06-Submenu_link_3"></Trans>
						</NavLink>
						<NavLink to="/profile/cards" activeClassName="active">
							<Trans i18nKey="06-Submenu_link_4"></Trans>
						</NavLink>
						<NavLink to="/profile/info" activeClassName="active">
							<Trans i18nKey="06-Submenu_link_5"></Trans>
						</NavLink>
					</nav>
				</Router>
				{windowSize > 768 &&
					<button className="log-out-button" onClick={logOutHandler}>
						<SVG src={logOut} onLoad={translateLogOut} />
					</button>
				}
			</section>
		</section>
	);
};

const ProfileHeaderInfo = (props) => {
	let {currentUser, amountBig} = props;
	let amountClass = amountBig ? 'profile-amount amount-big' : 'profile-amount';

	return (
		<div className="profile-header-info">
			<div className="profile-user-info">
				<span className="profile-info-text">{`${currentUser.firstName} ${currentUser.lastName}`}</span> <br />
				<span className="profile-user-card">{currentUser.masterCardNumber}</span>
			</div>
			<div className="profile-points-info">
				<span className="profile-info-text">
					<Trans i18nKey="06-Banner_balance"></Trans>
				</span>
				<br />
				<span className={amountClass}>{currentUser.accountBalance}</span>
			</div>
			<button className="show-user-card" onClick={props.showMemberCard}>
				<Trans i18nKey="06-Banner_button"></Trans>
			</button>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		webView: state.webView,
	};
};

export default connect(mapStateToProps)(translate('common')(ProfileHeader));
