import styled, { css } from 'styled-components';
import { BlueBlob } from '../../../../prepr/assets';

export const HeroBannerHolder = styled.div(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: relative;
    height: 600px;
    width: 100%;
    overflow: visible;
    margin-bottom: ${size.px40};

    .anchor-cta {
      position: absolute;
      z-index: 3;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
    }

    a:not(.anchor-cta),
    button {
      display: inline-block;
    }

    ${mediaQueries.md} {
      height: auto;
      margin-bottom: 0;

      display: flex;
      flex-direction: column;

      a:not(.anchor-cta),
      button {
        display: block;
      }

      .anchor-cta {
        display: none;
      }
    }
  `;
});

export const HeroBannerImageStyles = styled.img<{imageMobile: boolean}>(({ theme, imageMobile }) => {
  const { mediaQueries } = theme;

  return css`
    position: relative;
    width: 100%;
    height: 100%;

    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center right;
    object-position: center right;

    ${mediaQueries.xl} {
      -o-object-fit: cover;
      object-fit: cover;
    }

    ${mediaQueries.md} {
      -o-object-position: ${imageMobile ? 'center center' : 'center right'};
      object-position: ${imageMobile ? 'center center' : 'center right'};
      min-height: 44vh;
    }
    
    ${mediaQueries.sm} {
      min-height: 36vh;
    }

    ${mediaQueries.xs} {
      min-height: 28vh;
    }

    ${mediaQueries.s} {
      min-height: 240px;
    }
  `;
});

export const OvalBlobStyles = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    background: url(${BlueBlob}) center right no-repeat;
    background-size: cover;

    width: 52%;
    height: 100%;

    top: 0;
    left: 0;
    z-index: 1;

    ${mediaQueries.xl} {
      width: 55%;
    }

    ${mediaQueries.md} {
      position: static;

      background: none;
      flex-shrink: 0;
    }
  `;
});

export const HeroBannerContentWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    width: 100%;
    max-width: 1230px;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    ${mediaQueries.md} {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: -18%;
        right: -65%;
        background-color: #0047ba;
        width: 300%;
        height: 200%;
        border-radius: 150%;
      }
    }

    ${mediaQueries.sm} {
      &:before{
        top: -10%;
        right: -85%;
      }
    }
  `;
});

export const HeroBannerContent = styled.section(({ theme }) => {
  const { mediaQueries, size } = theme;

  return css`
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    width: 100%;
    max-width: 460px;
    padding: 0 ${size.px16};

    z-index: 2;

    ${mediaQueries.md} {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      padding: ${size.px16} ${size.px16} ${size.px30} ${size.px16};

      z-index: 1;
    }
  `;
});

export const HeroBannerTitle = styled.h2(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintSemi};
    font-size: ${size.px40};
    font-weight: ${font.weight.semiBold};

    margin: 0 0 ${size.px12};
    line-height: 1.25;
    color: ${color.white};
    max-width: 90%;

    ${mediaQueries.md} {
      font-size: ${size.px28};
      max-width: 100%;
    }
  `;
});

export const HeroBannerSubtitle = styled.h3(({ theme }) => {
  const { color, font, size, mediaQueries } = theme;

  return css`
    font-size: ${size.px18};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.semiBold};
    color: ${color.white};
    line-height: 1;
    margin: 0 0 ${size.px16};

    ${mediaQueries.md} {
      font-size: ${size.px16};
      font-weight: ${font.weight.regular};
    }
  `;
});

export const HeroBannerText = styled.p(({ theme }) => {
  const { color, font, size, mediaQueries } = theme;

  return css`
    font-size: ${size.px16};
    font-family: ${font.family.sansSerif};
    font-weight: ${font.weight.regular};
    color: ${color.white};
    line-height: 1.5;
    margin: 0 0 ${size.px55};

    ${mediaQueries.md} {
      margin: 0 0 ${size.px24};
    }
  `;
});

export const HeroBannerDescription = styled.div(({ theme }) => {
  const { font, size, color } = theme;

  return css`
    margin-top: 15px;
    
    h1, h2, h3, h4, h5, h6, p, a, span{
      color: ${color.white};
    }
    
    a {
      font-family: ${font.family.EMPrintRegular};
      font-size: ${size.px14};
      font-weight: ${font.weight.regular};
      text-decoration: underline;
      cursor: pointer;
    }
  `;
});

