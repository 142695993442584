import React from 'react';
import { Interpolate } from 'react-i18next';
import Modal from '../Modal';
import { Button } from '../../index';
import i18n from '../../../../../config/i18n';
import { ModalTypes } from '../Modal/modal.types';
import { ModalText } from '../Modal/Modal.styles';

const ChangePasswordModal: React.FC<ModalTypes> = ({ ...props }) => {
  const futureTime = new Date(Date.now() + 15 * 60 * 1000);
  const hours = futureTime.getHours().toString().padStart(2, '0');
  const minutes = futureTime.getMinutes().toString().padStart(2, '0');

  return (
    <Modal {...props}>
      <ModalText>
        <Interpolate i18nKey='modal_change_password_text'
                     useDangerouslySetInnerHTML={true}
                     time={`${hours}:${minutes}`} />
      </ModalText>
      <Button text={i18n.t('modal_change_password_button')} onClick={props.onClose} />
    </Modal>
  );
};

export default ChangePasswordModal;