import React from 'react';
import {HeroBannerProps} from './heroBanner.types';
import {Button} from '../index';
import {clickGTM} from '../../../../components/utils/_gtm-helpers';
import {useWindowSize} from '../../../hooks';
import {
  HeroBannerContent,
  HeroBannerHolder,
  HeroBannerImageStyles,
  HeroBannerSubtitle,
  HeroBannerText,
  HeroBannerTitle,
  OvalBlobStyles,
  HeroBannerContentWrapper, HeroBannerDescription
} from './HeroBanner.styles';

const HeroBanner: React.FC<HeroBannerProps> = (props) => {
  const {cta_label, cta_link, headline, superheadline, text, visual, visual_mobile, cta_type, additional_content} = props;
  const windowSize = useWindowSize();
  const img = visual?.[0];
  const imgMobile = visual_mobile?.[0];
  const imgDesktopUrl = img?.cdn_files?.[0]?.url || img?.url;
  const imgMobileUrl = imgMobile?.cdn_files?.[0]?.url || imgMobile?.url;
  const imageURL = windowSize > 1024 ? imgDesktopUrl : (imgMobileUrl || imgDesktopUrl);

  const handleClickGTM = () => {
    if (cta_link?.includes('e-bike')) {
      clickGTM('button_click', 'Button Clicks', 'Meer weten - eBike')
    }
  }
  return (
    <>
      <HeroBannerHolder>
        <HeroBannerImageStyles
          imageMobile={!!imgMobileUrl}
          loading='lazy'
          alt={headline}
          src={imageURL}
        />
        <HeroBannerContentWrapper>
          <HeroBannerContent>
            {headline && <HeroBannerSubtitle dangerouslySetInnerHTML={{__html: `${headline}`}}/>}
            {superheadline && <HeroBannerTitle dangerouslySetInnerHTML={{__html: `${superheadline}`}}/>}
            {text && <HeroBannerText dangerouslySetInnerHTML={{__html: `${text}`}}/>}
            {cta_label && cta_link && (
              <Button
                buttontype={cta_type || 'primary'}
                onClick={handleClickGTM}
                text={cta_label}
                url={cta_link}
                nativeLink={!!cta_link}/>
            )}
            {additional_content && <HeroBannerDescription dangerouslySetInnerHTML={{__html: `${additional_content}`}}/>}
          </HeroBannerContent>
        </HeroBannerContentWrapper>
        <OvalBlobStyles/>
      </HeroBannerHolder>
    </>
  );
};

export default HeroBanner;
