import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Toast} from '../components/shared';

type ToastTypes = 'error' | 'warning' | 'info' | 'success';

interface NotificationProps {
  toastType?: ToastTypes;
  positionTop?: string;
  width?: string;
}

const useNotification = ({toastType = 'error', positionTop, width}: NotificationProps) => {
  const [isShowed, setIsShowed] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState(toastType);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const close = useCallback(() => {
    setIsAnimated(true);
    setTimeout(() => {
      setIsShowed(false);
      setIsAnimated(false);
    }, 300);
  }, []);

  const showNotification = useCallback((message: string, type?: ToastTypes) => {
    setMessage(message);
    setIsAnimated(false);
    setIsShowed(true);
    type && setType(type);
  }, []);

  useEffect(() => {
    if (isShowed) {
      timerRef.current = setTimeout(() => {
        close();
      }, 3000);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      };
    }
  }, [isShowed, close]);

  const notificationComponent = isShowed ?
    <Toast
      toastType={type}
      isShowed={isShowed}
      onClose={close}
      isAnimated={isAnimated}
      width={width}
      positionTop={positionTop}
      title={message}
    />
    : null;

  return {showNotification, notificationComponent};
};

export default useNotification;