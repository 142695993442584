import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useWindowSize} from '../../../../../../../redesign/hooks';
import {shopLang} from '../../../../../../../config/config';
import {PrintBarWrapper, PrintBarBox, PrintResult} from './PrintBar.styles';
import {LotteryResultProps} from '../Device/device.types';

interface PrintBarProps {
  start?: boolean;
  startProgressValue?: number;
  minDuration?: number;
  onProgressComplete?: () => void;
  isUserWin: LotteryResultProps
}

const PrintBar: React.FC<PrintBarProps> = (props) => {
  const {
    start = false,
    startProgressValue = 0,
    minDuration = 3000,
    onProgressComplete,
    isUserWin
  } = props;

  const windowSize = useWindowSize();
  const [progress, setProgress] = useState(startProgressValue);
  const progressRef = useRef(startProgressValue);
  const isCompletedRef = useRef(false);
  const startTimeRef = useRef(0);
  const animationFrameRef = useRef<number | null>(null);

  const getProgressBarHeight = useCallback((progress: number) => {
    if (windowSize > 1440) return `calc(-285px - 4px + ${progress * 2.85}px)`;
    if (windowSize > 1025 && windowSize <= 1440) return `calc(-20vw - 3px + ${progress * 0.2}vw)`;
    if (windowSize > 600 && windowSize <= 1024) return `calc(-285px - 4px + ${progress * 2.85}px)`;
    return `calc(-50vw - 2px + ${progress * 0.5}vw)`;
  }, [windowSize]);

  useEffect(() => {
    setProgress(startProgressValue);
    progressRef.current = startProgressValue;
  }, [startProgressValue]);

  useEffect(() => {
    if (!start) return;

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    startTimeRef.current = Date.now();
    isCompletedRef.current = false;

    const updateProgress = () => {
      if (isCompletedRef.current) return;
      const elapsed = Date.now() - startTimeRef.current;
      let percentage = Math.min((elapsed / minDuration) * 100, 100);

      if (percentage > progressRef.current) {
        progressRef.current = percentage;
        setProgress(percentage);
      }

      if (percentage >= 100 && !isCompletedRef.current) {
        isCompletedRef.current = true;
        if (onProgressComplete) {
          onProgressComplete();
        }
      }

      if (percentage < 100) {
        animationFrameRef.current = requestAnimationFrame(updateProgress);
      }
    };

    animationFrameRef.current = requestAnimationFrame(updateProgress);

    return () => {
      isCompletedRef.current = true;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [start, minDuration, onProgressComplete]);

  return (
    <PrintBarWrapper>
      <PrintBarBox/>
      <PrintResult
        locale={shopLang}
        isUserWin={isUserWin}
        style={{top: getProgressBarHeight(progress)}}
      />
    </PrintBarWrapper>
  );
};
export default PrintBar;