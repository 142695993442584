import { useLocation } from 'react-router-dom';

const useProfileMenuLinkActive = () => {
  const location = useLocation();

  return (url: string) => {
    const { pathname } = location;
    return pathname === url
  };
};

export default useProfileMenuLinkActive;