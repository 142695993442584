import React, {useCallback, useEffect, useRef} from "react";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import {Link} from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from '../../../../../../../config/i18n';
import {apiLang, shopLang} from '../../../../../../../config/config';
import {useModal, useNotification} from "../../../../../../../redesign/hooks";
import APIMiniGamesService from '../../../api/api';
import { Button } from '../../../../../../../redesign/components/shared';
import Modal from "../../../../../../../redesign/components/shared/modals/Modal";
import {InputField} from "../../../../../../../redesign/components/shared/Form";
import {ModalContent} from "../../../../../../../redesign/components/shared/modals/Modal/Modal.styles";
import {InputWrap, FormWrapper, FormTitle, FormInfo, FormContact, ButtonBox} from './Form.styles';

const Form = ({visible} : {visible: boolean}) => {
  const {isShowed, toggle, close, isAnimated} = useModal();
  const { showNotification, notificationComponent } = useNotification({
    toastType: 'error',
    positionTop: '30px',
    width: 'auto',
  });
  const email = localStorage.getItem('userEmail');
  const firstName = useSelector((state: any) => state?.currentUser?.firstName);
  const lastName = useSelector((state: any) => state?.currentUser?.lastName);
  const participantId = useSelector((state: any) => state?.participantData?.id);

  const checkSubmitting = useRef(false);

  type Inputs = {
    firstName: string,
    lastName: string,
    address: string,
    postcode:string,
    city: string,
    email: string,
    phone: string,
  }

  const schema = yup
    .object()
    .shape({
      firstName: yup
          .string()
          .required(i18n.t('eBike_form_error_firstName'))
          .matches(/^(?!\s*$)[A-Za-zÀ-ÿ\s-.']{1,30}$/i, i18n.t('eBike_form_error_firstName')),
      lastName: yup
        .string()
        .required(i18n.t('eBike_form_error_lastName'))
        .matches(/^(?!\s*$)[A-Za-zÀ-ÿ\s-.']{1,30}$/i, i18n.t('eBike_form_error_lastName')),
      address: yup.string()
        .required(`${i18n.t('eBike_form_error_street')}`)
        .matches(/^(?!\s*$)[A-zÀ-ÿ0-9\s-.,'/]{1,40}$/i, `${i18n.t('eBike_form_error_street')}`),
      postcode: yup
        .string()
        .required(`${i18n.t('eBike_form_error_postcode')}`)
        .matches(
          (apiLang === 'nl') ?
            /^[0-9]{4}[A-zÀ-ÿ]{2}$/ :
            /^[0-9]{4}$/,
          `${i18n.t('eBike_form_error_postcode')}`),
      city: yup
        .string()
        .required(`${i18n.t('eBike_form_error_city')}`)
        .matches(/^(?!\s*$)[A-zÀ-ÿ\s-']{1,30}$/i, `${i18n.t('eBike_form_error_city')}`),
      email: yup
        .string()
        .required(`${i18n.t('eBike_form_error_email')}`)
        .matches(/^\s*[A-zÀ-ÿ0-9._%+-]+@[A-zÀ-ÿ0-9.-]+\.[A-zÀ-ÿ]{2,}\s*$/i, `${i18n.t('eBike_form_error_email')}`),
      phone: yup
        .string()
        .required( `${i18n.t('eBike_form_error_phone')}`)
        .matches(/^[0-9]+$/,  `${i18n.t('eBike_phone_2_validation')}`)
        .min(9, `${i18n.t('eBike_phone_2_validation')}`)
        .max(16, `${i18n.t('eBike_phone_2_validation')}`),
    }).required()

  const {
    register,
    setValue,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } =
    useForm<Inputs>({
      resolver: yupResolver(schema),
      mode: 'all'
  })

  useEffect(() => {
    return () => {
      checkSubmitting.current = false;
    };
  }, []);

  useEffect(() => {
    if(!isShowed){
      visible && toggle();
    }
  }, [isShowed, toggle, visible]);

  const handleSendData = useCallback((data: any) => {
    if (checkSubmitting.current) return null;
    checkSubmitting.current = true;

    const userData = {
      firstName: data.firstName?.trim() || '',
      lastName: data.lastName?.trim() || '',
      address1: data.address?.trim() || '',
      zip: data.postcode?.trim() || '',
      city: data.city?.trim() || '',
      email: data.email?.trim() || '',
      phone: data.phone?.trim() || '',
      country: shopLang.slice(0, 2).toLowerCase(),
      notice: '',
      dob: new Date().toString(),
    };

    APIMiniGamesService.sendForm(userData, participantId)
      .then(() => {
        close();
        APIMiniGamesService.setIsFormShowed(false);
      })
      .catch((error) => {
        console.error(error);
        showNotification('Something went wrong, please try again.');
      });
  },[close, participantId, showNotification]);

  return (
    <>
      <Modal
        overlayStyle={{
          backgroundColor: 'rgba(0, 33, 100, 0.6)',
          backdropFilter: 'blur(2px)'
        }}
        isModalCloseBtn={false}
        maxWidth='460px'
        isShowed={isShowed}
        isAnimated={isAnimated}
        onClose={() => {}}>
        <ModalContent>
          <FormWrapper>
            <FormTitle>{i18n.t('mc_form_title')}</FormTitle>

            <form onSubmit={handleSubmit(handleSendData)}>
              <InputWrap>
                <InputField
                  register={{
                    ...register('firstName')
                  }}
                  type='text'
                  placeholder={i18n.t('mc-form-default-placeholder')}
                  errorText={''}
                  name='firstName'
                  label={i18n.t('eBike_form_placeholder_firstName')}
                  width={50}
                  isError={!!errors.firstName}
                  defaultValue={firstName || ''}
                  isRequired
                />

                <InputField
                  register={{
                    ...register('lastName')
                  }}
                  type='text'
                  placeholder={i18n.t('mc-form-default-placeholder')}
                  errorText={''}
                  name='lastName'
                  label={i18n.t('eBike_form_placeholder_lastName')}
                  width={50}
                  isError={!!errors.lastName}
                  defaultValue={lastName || ''}
                  isRequired
                />
              </InputWrap>

              <InputWrap>
                <InputField
                  register={{
                    ...register('address')
                  }}
                  type='text'
                  placeholder={i18n.t('mc-form-default-placeholder')}
                  errorText={''}
                  name='address'
                  label={i18n.t('form_placeholder_address')}
                  width={100}
                  isError={!!errors.address}
                  isRequired
                />
              </InputWrap>

              <InputWrap>
                <InputField
                  register={{
                    ...register('postcode')
                  }}
                  type='text'
                  placeholder={i18n.t('mc-form-default-placeholder')}
                  errorText={''}
                  name='postcode'
                  label={i18n.t('eBike_form_placeholder_postcode')}
                  width={100}
                  isError={!!errors.postcode}
                  isRequired
                />
              </InputWrap>

              <InputWrap>
                <InputField
                  register={{
                    ...register('city')
                  }}
                  type='text'
                  placeholder={i18n.t('mc-form-default-placeholder')}
                  errorText={''}
                  name='city'
                  label={i18n.t('mc_form_placeholder_city')}
                  width={100}
                  isError={!!errors.city}
                  isRequired
                />
              </InputWrap>

              <InputWrap>
                <InputField
                  register={{
                    ...register('email')
                  }}
                  type='text'
                  placeholder={i18n.t('mc-form-default-placeholder')}
                  errorText={''}
                  name='email'
                  label={i18n.t('eBike_form_placeholder_email')}
                  width={100}
                  isError={!!errors.email}
                  defaultValue={email || ''}
                  isRequired
                />
              </InputWrap>

              <InputWrap>
                <InputField
                  register={{
                    ...register('phone')
                  }}
                  type={'tel'}
                  placeholder={'0612345678'}
                  errorText={''}
                  name='phone'
                  label={i18n.t('eBike_form_placeholder_phone')}
                  width={100}
                  isError={!!errors.phone}
                  isRequired
                  minLength={9}
                  maxLength={16}
                  handleOnChange={(fieldName, value) => {
                    let newValue = value.replace(/\D/g, '');
                    setValue('phone', newValue);
                  }}
                />
              </InputWrap>

              <FormInfo>{i18n.t('mc_form_info')}</FormInfo>

              <FormContact>
                <span>{i18n.t('mc_form_contact_text')}</span>&nbsp;
                <Link to={'/info-contact'}>{i18n.t('mc_form_contact_link')}</Link>
              </FormContact>

              <ButtonBox>
                <Button
                  disabled={!isValid || !isDirty || checkSubmitting.current}
                  text={`${i18n.t('06-my_card_submenu_unlink_button')}`}
                  buttontype={'primary'}
                  type={'submit'}/>
              </ButtonBox>
            </form>
          </FormWrapper>
        </ModalContent>
      </Modal>
      {notificationComponent}
    </>
  )
}
export default Form;
