import styled, {css} from 'styled-components';
import {Theme} from '../../../../redesign/theme';
import {Container as ContainerBase} from '../../../../redesign/theme/GlobalStyles';
import { Link } from "react-router-dom";

export const Container = styled(ContainerBase)<{ paddingSm?: boolean }>(({paddingSm}) => {
  return css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 760px;

    ${Theme.mediaQueries.xl} {
      min-height: 53vw;
    }

    ${Theme.mediaQueries.md} {
      min-height: auto;
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: ${!paddingSm && '180px'};
    }
  `
});

export const InfoBoxBase = styled.div`
  width: 35%;
  max-width: 400px;

  ${Theme.mediaQueries.md} {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
`;

export const LotteryBoxBase = styled.div`
  width: 63%;

  ${Theme.mediaQueries.md} {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: ${Theme.size.px32};
  font-family: ${Theme.font.family.EMPrintRegular};
  font-weight: ${Theme.font.weight.semiBold};
  color: ${Theme.color.white};
  line-height: 1.2;
  margin-bottom: 20px;

  ${Theme.mediaQueries.md} {
    font-size: ${Theme.size.px26};
    margin-bottom: 10px;
  }
`;

export const Text = styled.p`
  font-size: ${Theme.size.px16};
  font-family: ${Theme.font.family.sansSerif};
  font-weight: ${Theme.font.weight.regular};
  color: ${Theme.color.white};
  line-height: 1.5;
  margin-bottom: 60px;

  ${Theme.mediaQueries.md} {
    font-size: ${Theme.size.px14};
    margin-bottom: 20px;
  }
`;

export const Note = styled.p`
  font-size: ${Theme.size.px14};
  font-family: ${Theme.font.family.EMPrintRegular};
  font-style: ${Theme.font.style.italic};
  font-weight: ${Theme.font.weight.semiBold};
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.2;
  margin-bottom: 10px;

  ${Theme.mediaQueries.md} {
    font-size: ${Theme.size.px14};
  }
`;

const termsStyles = css`
  position: absolute;
  top: 40px;
  right: 25px;
  font-size: ${Theme.size.px10};
  font-family: ${Theme.font.family.EMPrintRegular};
  font-weight: ${Theme.font.weight.semiBold};
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.2;
  text-decoration: underline;
`;

export const Terms = styled.a`
  ${termsStyles};
`;

export const TermsLink = styled(Link)`
  ${termsStyles};
`;