import React from 'react';
import i18n from '../../../../../config/i18n';
import APIService from '../../../../../prepr/components/EndYearCampaignCustom/apiEndYearCampaign/api';
import {Button} from '../../../../components/shared';
import {
  ModalQuestionWrapper,
  ModalBlock,
  ButtonSendWrap,
  ModalSubtitle,
  ModalTitle,
  ModalCloseBtn
} from './ModalAllKeys.style';
import {IconClose} from '../../assets'

type ModalAllKeysProps = {
  setIsShowModalAllKeys: (val: boolean) => void;
  setIsAllKeysButtonClicked: (val: boolean) => void;
  setUseAllKeys: (val: boolean) => void;
}

function ModalAllKeys(props: ModalAllKeysProps) {
  const {setIsShowModalAllKeys, setIsAllKeysButtonClicked, setUseAllKeys} = props;

  const hideModal = () => {
    setIsShowModalAllKeys(false);
  };

  const onCloseButton = () => {
    setUseAllKeys(false);
    setIsAllKeysButtonClicked(false);
    APIService.setIsModalAnswerCorrect(false);
    hideModal();
  }

  const submitAnswer = (val: boolean) => {
    if (val) {
      setUseAllKeys(true);
      hideModal();
    } else {
      onCloseButton();
    }
  };

  return (
    <ModalQuestionWrapper>
      <ModalBlock>
        <ModalCloseBtn onClick={onCloseButton}>
          <img src={IconClose} alt='close modal'/>
        </ModalCloseBtn>
        <ModalSubtitle>{i18n.t('eyc_2024_modal_all_keys_intro')}</ModalSubtitle>
        <ModalTitle>{i18n.t('eyc_2024_modal_all_keys_question')}</ModalTitle>
        <ButtonSendWrap>
          <Button
            text={i18n.t('eyc_2024_modal_all_keys_1_button')}
            buttontype={'primary'}
            onClick={() => submitAnswer(true)}
          />
          <Button
            text={i18n.t('eyc_2024_modal_all_keys_2_button')}
            textcolor={'#003399'}
            backgroundcolor={'#F2F5FA'}
            onClick={() => submitAnswer(false)}
          />
        </ButtonSendWrap>
      </ModalBlock>
    </ModalQuestionWrapper>
  );
}

export default ModalAllKeys;