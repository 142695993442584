import React, {useEffect, useState} from 'react';

interface TimerProps {
  timeInMs: number;
}

const Timer: React.FC<TimerProps> = ({timeInMs}) => {
  const [timeLeft, setTimeLeft] = useState(timeInMs / 1000);
  // const [isVisible, setIsVisible] = useState(true);
  const [startTime] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = Math.floor((currentTime - startTime) / 1000);
      const remainingTime = Math.max(timeInMs / 1000 - elapsedTime, 0);

      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    // const handleVisibilityChange = () => {
    //   setIsVisible(!document.hidden);
    // };

    // document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [startTime, timeInMs]);

  const formatTime = (sec: number) => {
    const days = Math.floor(sec / (3600 * 24));
    const hours = Math.floor((sec % (3600 * 24)) / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    // const seconds = Math.floor(sec % 60);

    const formattedDays = String(days).padStart(2, '0');
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    // const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedDays}d:${formattedHours}u:${formattedMinutes}m`;
  };

  return (
    <>
      {timeLeft <= 0
        ? `00d:00u:00m`
        : `${formatTime(timeLeft)}`}
      {/*<p>{isVisible ? 'Tab is active' : 'Tab is hidden'}</p>*/}
    </>
  );
};

export default Timer;