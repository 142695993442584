import styled, {css} from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';
import {InfoBoxBase} from '../../../MiniGames.styles';

export const InfoBox = styled(InfoBoxBase)`
  svg {
    margin-bottom: 60px;
  }

  a, form {
    display: inline-flex;
  }
  
  ${Theme.mediaQueries.md} {
    svg {
      width: 115px;
      height: auto;
      margin-bottom: 30px;
    }

    a, form {
      margin-bottom: 10px;
      width: auto;
    }
  }
`;

export const ContentBox = styled.div<{increased?: boolean}>(({increased}) => {
  return css`
    min-height: 350px;
    position: relative;

    ${Theme.mediaQueries.md} {
      min-height: 200px;

      ${FloatBox} {
        ${increased && `
          top: calc(425px + 330px + 35px);
        `}
      }
    }

    ${Theme.mediaQueries.xxs} {
      ${FloatBox} {
        ${increased && `
          top: calc(75vw + 330px + 35px);
        `}
      }
    }
  `
});

export const FloatBox = styled.div`
  ${Theme.mediaQueries.md} {
    width: 100%;
    position: absolute;
    top: calc(425px + 200px + 35px);
    left: 50%;
    transform: translateX(-50%);
  }

  ${Theme.mediaQueries.xxs} {
    top: calc(75vw + 200px + 35px);
  }
`;

export const ButtonShop = styled.form`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${Theme.size.px14};
  border-radius: ${Theme.size.px4};
  border: none;

  font-family: ${Theme.font.family.EMPrintSemi};
  font-weight: 600;
  text-align: center;

  min-width: 158px;
  height: 48px;

  padding: ${Theme.size.px16} ${Theme.size.px32};
  margin-left: auto;
  margin-right: auto;
  color: ${Theme.color.white};
  background-color: ${Theme.color.navy};
  text-decoration: none;

  span {
    font-family: ${Theme.font.family.EMPrintSemi};
    color: ${Theme.color.white};
    display: block;
    line-height: 1;
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &:active{
    background-color: ${Theme.color.navy};
    border-color: ${Theme.color.navy};
    filter: brightness(90%);
  }

  &:focus,
  &:focus-visible {
    outline: 2px solid ${Theme.color.azure};
    transition: all 0.2s ease-in-out;
  }
`;