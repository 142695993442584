import styled, { css } from 'styled-components';
import { NavigationIcon } from '../../MainNavigation.styles';
import { IconDelete, IconProfileBlue, IconArrowDownBlue } from '../../../../../assets';

type ProfileIconTypes = {
  isDeleteStatus: boolean;
}

export const ProfileWrapper = styled.div(({ theme }) => {
  const { color, mediaQueries } = theme;

  return css`
    display: inline-flex;
    align-items: center;
    height: 100%;
    position: relative;

    &:hover {
      background-color: ${color.lightblue};
    }

    ${mediaQueries.md} {
      position: static;
    }
  `;
});

export const ProfileButton = styled.button(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    user-select: none;
    background: transparent;

    ${mediaQueries.md} {
      padding: 0 8px;
    }
  `;
});

export const ProfileIcon = styled(NavigationIcon)<ProfileIconTypes>(({ isDeleteStatus }) => {
  return css`
    position: relative;
    background-image: url('${IconProfileBlue}');
    min-width: 25px;
    min-height: 25px;

    svg {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 16px;
      height: 16px;
    }

    ${isDeleteStatus && `
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 16px;
        height: 16px;
        background-image: url('${IconDelete}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    `}
  `;
});

export const ProfileDetails = styled.span<{ openMenu: boolean }>(({ openMenu }) => {
  return css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    padding-right: 20px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 0;
      width: 12px;
      height: 12px;
      background-image: url('${IconArrowDownBlue}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: ${() => openMenu ? 'rotate(-180deg)' : 'rotate(0deg)'};
      transition: transform 0.2s;
    }
  `;
});

export const ProfileText = styled.span(({ theme }) => {
  const { font, size, color, mediaQueries } = theme;

  return css`
    font-family: ${font.family.EMPrintRegular};
    font-weight: ${font.weight.semiBold};
    font-size: ${size.px14};
    line-height: 1;
    color: ${color.blue};
    max-width: 250px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${mediaQueries.md} {
      max-width: inherit;
    }

    ${mediaQueries.s} {
      font-size: ${size.px12};
    }
  `;
});

export const ProfileMenuWrapper = styled.div(({ theme }) => {
  const { mediaQueries } = theme;

  return css`
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    min-width: 210px;
    z-index: -1;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    ${mediaQueries.md} {
      top: 60px;
      width: 100%;
      overflow-y: auto;
    }

    &.active {
      opacity: 1;
      z-index: 101;
    }
  `;
});


