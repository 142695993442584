import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useWindowSize } from '../../../hooks';
import { checkLoggedIn, isWebView } from '../../../../components/utils/_app-helpers';
import { Shop, Profile, LinksBar, HamburgerButton } from './parts';
import { BrandLinks, LanguageSelector } from '../TopNavigation/parts';

import {
  LogoBox,
  MainNavigationBox,
  MainNavigationWrapper,
  NavigationBar,
  NavigationBox,
  ContentLeft
} from './MainNavigation.styles';
import { HeaderContainer } from '../Header.styles';
import { IconLogoEssoExtras } from '../../../assets';

function MainNavigation() {
  const isLoggedIn = checkLoggedIn();
  const webView = isWebView();
  const location = useLocation();
  const windowSize = useWindowSize();
  const [isMobMenuActive, setIsMobMenuActive] = useState(false);
  const isCurrentUserDataLoaded = useSelector(
    (state: any) => state?.isCurrentUserDataLoaded
  );

  const handleSetIsMobMenuActive = () => {
    setIsMobMenuActive(prev => !prev);
  };

  useEffect(() => {
    !webView && setIsMobMenuActive(false);
  }, [location, webView]);

  return (
    <MainNavigationWrapper>
      <HeaderContainer>
        <MainNavigationBox>
          <ContentLeft>
            <HamburgerButton setIsMobMenuActive={handleSetIsMobMenuActive}
                             isMobMenuActive={isMobMenuActive} />

            <LogoBox to='/'>
              <img src={IconLogoEssoExtras} alt='logo' />
            </LogoBox>
          </ContentLeft>
          {
            ((isLoggedIn && isCurrentUserDataLoaded) || !isLoggedIn) &&
            <NavigationBox>
              <NavigationBar className={isMobMenuActive ? 'active' : undefined}>
                {windowSize <= 1024 && <BrandLinks />}
                <LinksBar />
                {windowSize <= 1024 && <LanguageSelector />}
              </NavigationBar>

              <Profile />

              {isLoggedIn && <Shop />}
            </NavigationBox>
          }
        </MainNavigationBox>
      </HeaderContainer>
    </MainNavigationWrapper>
  );
}

export default MainNavigation;
