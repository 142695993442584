import styled, {css} from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';
import {Device} from '../../assets';
import {LotteryResultProps} from './device.types';
import {ProgressIndicator} from '../ProgressBar/ProgressBar.styles';
import {Text} from '../Typewriter/Typewriter.styles';

const WIN_COLOR = '#35CE54';
const LOSS_COLOR = '#FF303E';
const DEFAULT_COLOR = '#00AEFF';

export const DeviceBox = styled.div`
  width: 100%;
  height: 100%;
  background: url('${Device}') no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;

  ${Theme.mediaQueries.md} {
  }
`;

type DeviceContentProps = {
  isUserWinner?: LotteryResultProps
}

export const DeviceContent = styled.div<DeviceContentProps>(({isUserWinner = null}) => {
  return css`
    position: absolute;
    top: 0;
    width: 350px;
    left: calc((500px - 350px) / 2);

    ${Theme.mediaQueries.xl} {
      width: 25vw;
      left: calc((35vw - 25vw) / 2);
    }

    ${Theme.mediaQueries.md} {
      width: 350px;
      left: calc((500px - 350px) / 2);
    }

    ${Theme.mediaQueries.xxs} {
      width: 62vw;
      left: calc((86vw - 62vw) / 2);
    }

    ${Text} {
      color: ${() => isUserWinner ? WIN_COLOR : isUserWinner === false ? LOSS_COLOR : DEFAULT_COLOR};
      text-shadow: ${() => isUserWinner ? `0 0 12px ${WIN_COLOR}` :
        isUserWinner === false ? `0 0 12px ${LOSS_COLOR}` : `0 0 12px ${DEFAULT_COLOR}`};
    }

    ${ProgressIndicator} {
      background-color: ${() => isUserWinner ? WIN_COLOR : isUserWinner === false ? LOSS_COLOR : DEFAULT_COLOR};
    }
  `;
});

export const ResultIndicator = styled.div`
  position: absolute;
  top: 290px;
  right: 0;
  width: 24px;
  height: 8px;
  border-radius: 10px;
  background: #00FF33;
  border: 2px solid ${Theme.color.black};
  transition: all ease 0.3s;

  ${Theme.mediaQueries.xl} {
    top: 20.3vw;
  }

  ${Theme.mediaQueries.md} {
    top: 290px;
  }

  ${Theme.mediaQueries.xxs} {
    top: 50vw;
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 190px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background: #F37427;
  border-radius: 4px;
  border: 1px solid ${Theme.color.black};
  transition: all ease 0.3s;

  font-family: 'Tiny5', serif;
  font-weight: ${Theme.font.weight.regular};
  font-style: normal;
  font-size: 15px;
  line-height: 1.2;
  color: ${Theme.color.white};
  text-transform: uppercase;

  min-height: 50px;
  min-width: 150px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border-width: 0.78px 0.78px 2.33px 0.78px;
    border-style: solid;
    border-color: #A25120;
  }

  &:disabled {
    cursor: default;
    background: ${Theme.color.grey};
    color: rgba(255, 255, 255, 0.4);

    &:before {
      border-color: #7D7D7D;
    }
  }

  ${Theme.mediaQueries.xl} {
    top: 13vw;
  }

  ${Theme.mediaQueries.md} {
    top: 190px;
  }

  ${Theme.mediaQueries.xxs} {
    font-size: 11px;
    min-height: 8.5vw;
    top: 33vw;
  }

  ${Theme.mediaQueries.s} {
    min-height: 36px;
  }
`;