import styled, {css} from 'styled-components';
import {Theme} from '../../../theme';

type ButtonProps = {
  color: 'primary' | 'inherit';
};

export const PaginationWrapper = styled.div(() => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  `;
});

const BaseButton = styled.button(() => {
  return css`
    min-width: 34px;
    height: 38px;
    padding: 5px;
    margin: 0 3px;
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      cursor: default;

      svg {
        path {
          fill: ${Theme.color.grey};
        }
      }
    }

    svg {
      path {
        fill: ${Theme.color.blue};
      }
    }
  `
})

export const Button = styled(BaseButton)<{ color: ButtonProps['color'] }>(({color}) => {
  return css`
    background: ${color === 'primary' ? 'transparent' : Theme.color.white};
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: ${color === 'primary' ? Theme.color.blue : Theme.color.cottonGrey};

    font-family: ${Theme.font.family.sansSerif};
    font-weight: ${Theme.font.weight.regular};
    font-size: ${Theme.size.px14};
    color: ${color === 'primary' ? Theme.color.blue : Theme.color.grey};
    line-height: 1;
  `;
});

export const Dots = styled.span(() => {
  return css`
    margin: 0 5px;
  `;
});

export const PrevButton = styled(BaseButton)(() => {
  return css`
    svg {
      transform: rotate(90deg);
    }
  `;
});

export const NextButton = styled(BaseButton)(() => {
  return css`
    svg {
      transform: rotate(-90deg);
    }
  `;
});