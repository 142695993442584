import React from 'react';
import { useSelector } from 'react-redux';
import QR from 'qr-image';
import i18n from '../../../../../config/i18n';
import Modal from '../Modal';
import { ModalTypes } from '../Modal/modal.types';
import { IconLogoEssoColoredComponent } from '../../../../assets';
import { ModalTitle } from '../Modal/Modal.styles';
import { GeneratedQRCode, PhoneNumber } from './QRCodeModal.styles';

const QRCodeModal: React.FC<ModalTypes> = ({ ...props }) => {
  const cardsNum = useSelector((state: { cardsData: any }) => state?.cardsData?.cards?.find((card: {
    IsMasterCard: any;
  }) => card?.IsMasterCard)?.CardNumber);
  const generatedQR = cardsNum && QR.imageSync(cardsNum, { type: 'svg' });
  const linkedPhoneNumber = useSelector((state: { currentUser: any }) => state?.currentUser?.linkedPhoneNumber);
  const maskLinkedPhoneNumber = linkedPhoneNumber.replace(/./g, (c: any, i: number) => i < linkedPhoneNumber.length - 3 ? '*' : c);

  return (
    <Modal {...props}>
      <IconLogoEssoColoredComponent />
      <GeneratedQRCode dangerouslySetInnerHTML={{ __html: generatedQR }} />
      <ModalTitle>{i18n.t('qr_code_modal_text')}</ModalTitle>
      {linkedPhoneNumber &&
        <PhoneNumber>
          <span>{i18n.t('06-my_data_membercard_tel')}</span>&nbsp;
          <span>{maskLinkedPhoneNumber}</span>
        </PhoneNumber>
      }
    </Modal>
  );
};

export default QRCodeModal;
