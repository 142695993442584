import axios from 'axios';
import { apiURL, apiCampaignsURL } from '../../config/config';

export const getCyclooData = (searchParams, accessToken) => {
  return axios({
    url: apiURL + `/api/Cycloo/badge${searchParams}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": accessToken
    },
  })
}

export const checkSegmentedCustomerGroup = (token) => {
  return axios({
    url: apiCampaignsURL + `/Campaign/accounts/campaignStatus`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "AccessToken": token
    }
  })
}