import styled, { css } from 'styled-components';

export const GeneratedQRCode = styled.div(({ theme }) => {
  const { color } = theme;

  return css`
    background: ${color.white};
    border-radius: 10px;
    max-width: 85%;
    margin: 20px auto;

    svg {
      fill: ${color.blue};
    }
  `;
});

export const PhoneNumber = styled.p(({ theme }) => {
  const { color, size } = theme;

  return css`
    span {
      font-size: ${size.px14};
      color: ${color.white};
    }
  `;
});