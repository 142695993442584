import styled from 'styled-components';
import {Theme} from '../../../../../../../redesign/theme';

export const ProgressBarBox = styled.div`
  background: linear-gradient(180deg, #333333 0%, #4C4C4C 61.5%, #333333 96.5%);
  z-index: 2;
  border: 2px solid ${Theme.color.black};
  border-radius: 5px;

  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;
  height: 8px;

  ${Theme.mediaQueries.xl} {
    top: 11vw;
  }

  ${Theme.mediaQueries.md} {
    top: 160px;
  }

  ${Theme.mediaQueries.xxs} {
    top: 27vw;
  }
`;

export const ProgressIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
  transition: width 0.1s ease-out;
`;