import i18n from '../../../../../config/i18n';
export const errorsList= {
  email: {
    required: i18n.t('wrong_email'),
  },
  firstName: {
    required: i18n.t('08_wrong_first_name'),
  },
  lastName: {
    required: i18n.t('08_wrong_last_name'),
  },
  phoneNumber: {
    required: i18n.t('08_wrong_phone_number'),
    nullStarted: i18n.t('05_phonenumber_validation1'),
    onlyNumbers: i18n.t('05_phonenumber_validation2'),
    minMaxLength: i18n.t('05_phonenumber_validation3'),
    selectCountry: i18n.t('05_Registration_phonenumber_popup_title'),
  },
  cardNumber: {
    required: i18n.t('08_wrong_card_number_4006'),
    format: i18n.t('card_number_format'),
  },
  cardCode: {
    required: i18n.t('card_code_required'),
  },
  question:{
    required: i18n.t('08_wrong_question'),
  },
  password:{
    required: i18n.t('08_wrong_password'),
    uppercase: i18n.t('05_password-validation-uppercase'),
    lowercase: i18n.t('05_password-validation-lowercase'),
    number: i18n.t('05_password-validation-number'),
    specialCharacter: i18n.t('05_password-validation-specialCharacter'),
    eightCharacter: i18n.t('05_password-validation-eightCharacter'),
    match: i18n.t('05_password-validation-samePasswords'),
  },
  birthday: {
    required: 'DD/MM/YYYY'
  },
  accessCode:{
    required: 'access code is a required field'
  },
}