import React from 'react';
import Device from '../Device';
import {shopLang} from '../../../../../../../config/config';
import {CardsGroup, Card, LotteryBox, LotteryContent, Set} from './Lottery.styles';
import {cardsStyles} from './lottery.const';

const Lottery = ({isUserLoggedIn}: { isUserLoggedIn: boolean }) => {
  return (
    <LotteryBox>
      <LotteryContent>
        <Device isUserLoggedIn={isUserLoggedIn}/>
        {
          shopLang === 'nl_nl' ?
            <CardsGroup>
              {cardsStyles.map((card, index) =>
                <Card key={card.id}
                      lg={card.lg}
                      md={card.md}/>
              )}
            </CardsGroup> :
            <Set/>
        }
      </LotteryContent>
    </LotteryBox>
  );
};

export default Lottery;